import React, {Fragment, useEffect} from "react";
import {Card, Col, Input, message, Row} from "antd";
import advertiserApiService from "../../../util/services/AdvertisersApiService";
import AdvertisersApiService from "../../../util/services/AdvertisersApiService";
import IntlMessages, {translateMsg} from "../../../util/IntlMessages";
import {useIntl} from "react-intl";
import IgAccountInfo from "../../../components/Widgets/IgAccountInfo";
import '../../../assets/scss/App.scss'
import siteConfig from "../../../config/site.config";
import EcommerceStatus from "../../../components/Metrics/EcommerceStatus";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import IconWithTextCard from "../../../components/dashboard/IconWithTextCard";
import DateCountdown from "react-date-countdown-timer";
import Loader from "../../compain-manager/loader";

const Search = Input.Search;

const EngagementInfoAgency = () => {
    const [igAccountInfo, setIgAccountInfo] = React.useState(null);
    const [igUserName, setIgUserName] = React.useState(null);
    const [advertiser, setAdvertiser] = React.useState(null);
    const ad_id = localStorage.getItem('ad_id') ? localStorage.getItem('ad_id') : null
    const [showAlert, setShowAlert] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [is_valid, setIsValid] = React.useState(localStorage.getItem('is_valid') || null);
    const [initApp, setInitApp] = React.useState(true);
    const [trialDate, setTrialDate] = React.useState('');
    const [appIsInited, setAppIsInited] = React.useState(false);
    const [agency, setAgency] = React.useState({});
    const [disableFilter, setDisableFilter] = React.useState(false);

    const intl = useIntl();
    const customSearch = ((ig_info) => {
        let ig = 'https://www.instagram.com/';
        let src = 'hl=fr';
        let specific_car = '/';
        let data = ig_info.replace(ig, '');
        let data_src = data.replace(src, '');
        let ig_code = data_src.replace(specific_car, '');
        let ig_code_f = ig_code.replace('?','');
        setIgUserName(ig_code_f);
    });

    useEffect(() => {
        getAGInfo()
    }, [initApp]);
    const getAGInfo = () => {
        AdvertisersApiService.getMe()
            .then(res => {
                let profile_info = res.data.data.profile;
                setTrialDate(profile_info?.free_trial_expire_date);
                setAgency(profile_info || null);
                setAppIsInited(true);
            });
    };
    useEffect(() => {
        if (igUserName && igUserName !== '') {
            setIgAccountInfo(null)
            setLoading(true)
            let formData = {
                ig_username: igUserName
            }
            advertiserApiService
                .getIGAccountInfo(formData)
                .then(res => {
                    setIgAccountInfo(res.data.data);
                    handleChangeSearchCountEng();
                    setLoading(false);
                }).catch(err => {
                if (err.response.data && err.response.data.messages) {
                    setIgAccountInfo(null);
                    err.response.data.messages.forEach(msg => {
                        message.error(translateMsg(msg, intl));
                        setLoading(false);
                    })
                }
            });
        } else {
            setIgAccountInfo(null);
        }
    }, [igUserName]);

    useEffect(() => {
        advertiserApiService
            .getMe(ad_id)
            .then(res => {
                setAdvertiser(res.data.data.profile || null);
            });
    }, [ad_id]);

    const callbackFBConnector = (data) => {
        setAdvertiser({...advertiser, ...{is_valid: 'Y'}});
        message.success(translateMsg("AccountValidatedWithSuccess", intl), 3, () => {
            window.location.reload();
        });
    };

    const disableAlert = () => {
        setShowAlert(false)
    };
    const handleChangeSearchCountEng = () => {
        const formAgency = {search_count_eng: agency.search_count_eng + 1};
        formAgency.advertiser_id = agency.advertiser_id;
        if (formAgency.search_count_eng > siteConfig.Max_request_eng || trialDate < moment(new Date()).format('YYYY-MM-DD')) {
            setIgAccountInfo(null);
            setDisableFilter(true);
        } else {
            advertiserApiService.update(formAgency).then(res => {
                getAGInfo();
            }).catch(err => {
                const {data} = err.response;
                if (data && data.messages) {
                    data.messages.forEach(msg => {
                        message.error(translateMsg(msg, intl));
                    });
                }
            });
        }
    }
    const onCancelAlert = () => {
        setDisableFilter(false);
    }
    return (
        <>
            {appIsInited ?
                <Fragment>
                    <Card>
                        <Row>
                            <Col xl={12} lg={8} md={8} sm={12} xs={24}>
                                <IconWithTextCard icon="visits"
                                                  title={agency?.search_count_eng ? agency?.search_count_eng + '/' + siteConfig.Max_request_eng :0 + '/' + siteConfig.Max_request_eng}
                                                  cardColor="primary" subTitle="Requests"/>
                            </Col>
                            {
                                trialDate ?
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="ml-auto mr-3">
                                        <div className={"gx-mb-3"} style={{textAlign: "center"}}>
                                            <IconWithTextCard cardColor="red" icon="calendar-new"
                                                              title={<DateCountdown dateTo={trialDate}/>}/>
                                        </div>
                                    </Col>:''
                            }
                        </Row>
                    </Card>
                    <Card className="gx-card" title="Engagement rate calculator">
                        <>
                            <Search
                                placeholder="Enter instagram profile"
                                onSearch={value => customSearch(value)}
                                enterButton
                            />
                            {igAccountInfo ?
                                <>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                            <IgAccountInfo igAccountInfo={igAccountInfo}/>
                                        </Col>
                                    </Row>
                                </>
                                : ''
                            }
                            <SweetAlert show={disableFilter}
                                        warning
                                        cancelBtnBsStyle="default"
                                        title={<IntlMessages id="sweetAlerts.limitedRequest"/>}
                                        onConfirm={onCancelAlert}
                            >
                                <IntlMessages id="sweetAlerts.contactAdmin"/> <h5>midox@tawa.digital</h5>
                            </SweetAlert>
                            {
                                loading ?
                                    <Loader/>:''
                            }

                        </>
                    </Card>
                </Fragment>
                : <Loader/>
            }
        </>

    );
};

export default EngagementInfoAgency;
