import React from "react";
import {Menu, Table} from "antd";
import Widget from "../../../components/Widget";
import {Link} from "react-router-dom";
import siteConfig from "../../../config/site.config";

const Collaborations = ({collaborations}) =>{
  const columns = [
    {
      title: 'Photo ',
      dataIndex: 'profile_efile_id',
      render: (text, record) => {
        return <div className="gx-flex-row gx-align-items-center">
          <Link to={siteConfig.prefixMe + "/mes-collaborations/"+ record?.compain_id}>
          <img className="gx-rounded-circle gx-size-30 gx-mr-2" src={siteConfig.AppConfig.baseStyleImageUrl + record?.compain_info?.advertiser?.profile_efile_id} alt=""/>
          </Link>
          </div>
      },
    },
    {
      title: 'Marques ',
      dataIndex: 'marque',
      render: (text, record) => {
        return <div className="gx-flex-row gx-align-items-center">
          {/*<img className="gx-rounded-circle gx-size-30 gx-mr-2" src={siteConfig.AppConfig.baseStyleImageUrl + text} alt=""/>*/}
          <p className="gx-mb-0"><Link to={siteConfig.prefixMe + "/mes-collaborations/"+ record.compain_id}>{record.marque}</Link></p>
        </div>
      },
    },
    {
      title: 'Campagne',
      dataIndex: 'compain_name',
      render: (text, record) => {
        return <span className="gx-text-grey"><Link to={siteConfig.prefixMe + "/mes-collaborations/"+ record.compain_id}>{record.compain_name}</Link></span>
      },

    },
    {
      title: 'Date de Début',
      dataIndex: 'start_date_at',
      render: (text,record) => {
        return <span className="gx-text-primary gx-pointer">
          <i className="gx-text-grey"/><Link to={siteConfig.prefixMe + "/mes-collaborations/"+ record.compain_id}>{record.start_date_at}</Link></span>
      },
    },
    {
      title: 'Date de fin ',
      dataIndex: 'end_date_at',
      render: (text, record) => {
        return <span className="gx-text-primary gx-pointer">
          <i className="gx-text-grey"/><Link to={siteConfig.prefixMe + "/mes-collaborations/"+ record.compain_id}>{record.end_date_at}</Link></span>
      },
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      render: (text, record) => {
        return <span className="gx-text-primary gx-pointer">
          <button className="ant-btn ant-btn-success"> <Link to={siteConfig.prefixMe + "/mes-collaborations/"+ record.compain_id}>{record.status && record.status === 'need_validation'? 'En cours de traitement':record.status }</Link></button></span>
      },
    },

  ];

  return (
    <Widget
      title={
        <h2 className="h4 gx-text-capitalize gx-mb-0">
          Mes Collaborations</h2>
      }>
      <div className="gx-table-responsive">
        <Table className="gx-table-no-bordered" columns={columns} dataSource={collaborations} pagination={true}
               size="small"/>
      </div>
    </Widget>
  );
};

export default Collaborations;
