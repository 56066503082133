import React, {useEffect} from "react";
import talentApiService from "../../../util/services/TalentsApiService";
import Widget from "../../../components/Widget";
import siteConfig from "../../../config/site.config";
import {Button, Card, Col, DatePicker, Form, Input, message, Modal, Row, Select, Table, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {SocialIcon} from 'react-social-icons';
import moment from "moment";
import {post} from "axios";
import compainApiService from "../../../util/services/CompainApiService";
import {useHistory} from 'react-router-dom';
import categoriesApiService from "../../../util/services/CategoriesApiService";
import {tawaCities} from "../../../util/cities";
import {tawaAges} from "../../../util/ages";
import {tawaGender} from "../../../util/gender";
import {tawaEngagement_rate} from "../../../util/engagementRate";
import {tawaFollowers_count} from "../../../util/followersCount";
import {abbrNum, pourcentNum, unaccent} from "../../../util/helpers";

const Search = Input.Search;

const AddPrivateCampaign = (props) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const {TextArea} = Input;
    const [Talents, setTalents] = React.useState([]);
    const [TalentsInvited, setTalentsInvited] = React.useState([]);
    const [compain_efile_id, setCompain_efile_id] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewPicture, setPreviewPicture] = React.useState('');
    const [categories, setCategories] = React.useState([]);
    const [initApp, setInitApp] = React.useState(true);
    const [meta_key, setMeta_key] = React.useState('');
    const [appIsInited, setAppIsInited] = React.useState(false);
    const [filtres, setFiltres] = React.useState( null);
    const [filtresCategories, setFiltresCategories] = React.useState( null);
    const [formFilter] = Form.useForm();

    const filterElements = [
        {
            idx: 0,
            label: 'Categories',
            value: 'categories',
            data: categories,
            key_value: 'category_id'
        },
        {
            idx: 1,
            label: 'Ville',
            value: 'ville',
            data: tawaCities,
            key_value: 'value'
        },
        {
            idx: 2,
            label: 'Age',
            value: 'age_intervale',
            data: tawaAges,
            key_value: 'value'
        },
        {
            idx: 3,
            label: 'Gender',
            value: 'gender',
            data: tawaGender,
            key_value: 'value'
        },
        {
            idx: 4,
            label: 'Engagement rate',
            value: 'engagement_rate',
            data: tawaEngagement_rate,
            key_value: 'value'
        },
        {
            idx: 5,
            label: 'Followers count',
            value: 'followers_count',
            data: tawaFollowers_count,
            key_value: 'value'
        },
    ];

    const getFieldsSearch = () => {
        const fieldsSearch = [];

        filterElements.forEach(item_filter => {
            fieldsSearch.push(
                <Col lg={8} md={8} sm={12} xs={24} key={item_filter.idx} style={{display: 'block'}}>
                    <div className="gx-form-row0">
                        <Form.Item
                            name={item_filter.value}
                            label={item_filter.label}
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                style={{width: 400}}
                                placeholder={"Select a " + item_filter.label}
                                optionFilterProp="children"
                                filterOption={(input, option) => unaccent(option.props.children.toLowerCase()).indexOf(unaccent(input.toLowerCase())) >= 0}
                            >
                                {item_filter?.data?.map(option => {
                                    return <option value={option[item_filter.key_value]}>{option.label}</option>
                                })}

                            </Select>
                        </Form.Item>
                    </div>
                </Col>,
            );
        })

        return fieldsSearch;
    };

    useEffect(() => {
        categoriesApiService.find({limit: -1}).then(res => {
            setCategories(res.data.data || []);
        });
    }, [initApp]);

    useEffect(() => {
        getTalentInvited()
    }, [appIsInited, Talents]);

    const getTalents = (filter, categories) => {
        setFiltres(filter);
        setFiltresCategories(categories);
        filter.is_valid = true;
        talentApiService
            .getTalents({
                limit: -1,
                categories: categories ? categories.categories : null,
                filter: filter
            })
            .then(res => {
                let dataTalents = res.data.data || [];
                dataTalents.forEach(t => {
                    t.key = t.influencer_id;
                })
                setTalents(dataTalents);
                setAppIsInited(true);
            },() => {
                getTalentInvited();
            });
    };

    const getTalentInvited = () => {
        if (TalentsInvited && TalentsInvited.length !== 0) {
            let talents_filtred = Talents
            TalentsInvited.forEach((item) => {
                let idx = talents_filtred.findIndex(item_influencer => {
                    return (item_influencer.influencer_id && item && item.influencer_id && item_influencer.influencer_id === item.influencer_id);
                });
                if (idx !== -1) {
                    talents_filtred.splice(idx, 1);
                }
            });
            setTalents(talents_filtred);
        } else {
            setTalents(Talents);
        }
    };

    const InfoTalentsColumns = [
        {
            title: 'Talent Picture',
            dataIndex: 'profile_efile_id',
            render: (text, record) => {
                return <div className="gx-flex-row gx-align-items-center">
                    <img className="gx-rounded-circle gx-size-30 gx-mr-2"
                         src={siteConfig.AppConfig.baseStyleImageUrl + text}
                         alt=""/>
                </div>
            },
        },
        {
            title: 'Talent Name ',
            render: (text, record) => {
                return <div className="gx-flex-row gx-align-items-center">
                    <span className="gx-mb-0"><a href={siteConfig.TalentUrl + '/' + record?.username}
                                                 target="_blank">{record?.influencer_name}</a></span>
                </div>
            },
        },
        {
            title: 'Engagement rate',
            dataIndex: 'engagement_rate',
            render: (text, record) => {
                return <span className="gx-text-grey">{pourcentNum(record?.engagement_rate, 2) + '%'}</span>
            },

        },
        {
            title: 'Followers',
            dataIndex: 'Followers',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{abbrNum(record?.followers_count,2)}</span>
            },
        },
        {
            title: 'Instagram Account',
            dataIndex: 'instagram_url',
            render: (text, record) => {
                return <span className="gx-text-grey"><a href={record?.instagram_url} target="_blank"><SocialIcon
                    network={"instagram"}
                    url={record?.instagram_url}/></a></span>
            },

        },

        {
            title: 'Actions',
            dataIndex: 'status',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
          <button className="ant-btn ant-btn-success" onClick={() => inviteTalent(record)}>Inviter</button></span>
            },
        },

    ];

    const InfoTalentsInvitedColumns = [
        {
            title: 'Talent ',
            dataIndex: 'profile_efile_id',
            render: (text, record) => {
                return <div className="gx-flex-row gx-align-items-center">
                    <img className="gx-rounded-circle gx-size-30 gx-mr-2"
                         src={siteConfig.AppConfig.baseStyleImageUrl + text}
                         alt=""/>
                </div>
            },
        },
        {
            title: 'Talent Name ',
            render: (text, record) => {
                return <div className="gx-flex-row gx-align-items-center">
                    <span className="gx-mb-0"><a href={siteConfig.TalentUrl + '/' + record?.username}
                                                 target="_blank">{record?.influencer_name}</a></span>
                </div>
            },
        },
        {
            title: 'Engagement rate',
            dataIndex: 'engagement_rate',
            render: (text, record) => {
                return <span className="gx-text-grey">{pourcentNum(record?.engagement_rate, 2) + '%'}</span>
            },

        },
        {
            title: 'Followers',
            dataIndex: 'Followers',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{abbrNum(record?.followers_count,2)}</span>
            },
        },
        {
            title: 'Instagram Account',
            dataIndex: 'instagram_url',
            render: (text, record) => {
                return <span className="gx-text-grey"><a href={record?.instagram_url} target="_blank"><SocialIcon
                    network={"instagram"}
                    url={record?.instagram_url}/></a></span>
            },

        },
        {
            title: 'Actions',
            dataIndex: 'status',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
          <button className="ant-btn ant-btn-success" onClick={() => deleteTalent(record)}>Delete</button></span>
            },
        },

    ];

    const onFinish = values => {
        const ad_id = localStorage.getItem('ad_id') ? localStorage.getItem('ad_id') : null
        const influencers_ids = []
        if (TalentsInvited && TalentsInvited.length !== 0) {
            TalentsInvited.forEach(item_talent => {
                influencers_ids.push(item_talent.influencer_id)
            })
        }
        let FormData = values
        FormData.start_date_at = moment(values.start_date_at).format('YYYY-MM-DD')
        FormData.end_date_at = moment(values.end_date_at).format('YYYY-MM-DD')
        FormData.advertiser_id = ad_id
        FormData.category = 'private'
        FormData.influencers_ids = influencers_ids
        FormData.image_efile_id = compain_efile_id
        compainApiService.addCompain(FormData).then(res => {
            if (res.data.success === true) {
                message.success('Compaign Created With success');
                history.push('/me/private-compains')
            } else {
                message.error(res.data.message);
            }
        })
    };

    const handleCancel = () => {
        setPreviewVisible(false)
    };

    const handlePreview = (file) => {
        setPreviewPicture(file.url || file.thumbUrl)
        setPreviewVisible(true)
    };

    const compainImage = [{
        uid: -1,
        name: 'compain image',
        status: 'done',
        url: siteConfig.AppConfig.baseStyleImageUrl + compain_efile_id,
    }];

    const handleChange = () => {
        setCompain_efile_id(null)
    };

    const inviteTalent = value => {
        setTalentsInvited([
            ...TalentsInvited,
            value
        ]);
        let talent_updated = Talents
        let idx = talent_updated.findIndex(item_talent => item_talent === value)
        talent_updated.splice(idx, 1)
        setTalents([
            ...talent_updated
        ]);
    };

    const deleteTalent = value => {
        setTalents([
            ...Talents,
            value
        ]);
        let talent_invited_updated = TalentsInvited
        let idx = talent_invited_updated.findIndex(item_talent => item_talent === value)
        talent_invited_updated.splice(idx, 1)
        setTalentsInvited([
            ...talent_invited_updated
        ]);
    };

    const handleUploadClick = event => {
        const file = event.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            return post(siteConfig.AppConfig.fileUploadUrl, formData, {
                headers: {
                    'Content-Type': `multipart/form-data;`,
                },
                timeout: 30000,
            }).then(res => {
                setCompain_efile_id(res.data.data)
            })
        }
    };

    const resetFilter = () => {
        formFilter.resetFields();
        setTalents([])
    }

    const onFiltre = values => {
        if (values.categories && values.categories.length !== 0 || values.gender && values.gender.length !== 0 || values.age_intervale && values.age_intervale.length !== 0
            || values.ville && values.ville.length !== 0 || values.engagement_rate && values.engagement_rate.length !== 0 || values.followers_count && values.followers_count.length !== 0) {
            let categories = {
                'categories': values.categories
            };
            if (values.categories || values?.categories?.length !== 0) {
                delete values['categories'];
            }
            Object.keys(values).forEach(item_filter => {
                if (values[item_filter] && values[item_filter].length === 0) {
                    delete values[item_filter]
                }
            })
            values.is_valid = ['Y']
            getTalents(values, categories)
        } else {
            setTalents([])
            message.error('please choose a filter element')
        }

    };

    const onFiltreTalentsByCode = (code) => {
        if (code && code !== '') {
            setMeta_key(code);
            let filter_values   = {...filtres};
            filter_values.meta_key = code;
            getTalents(filter_values, filtresCategories)
        } else {
            let filter_values   = {...filtres};
            filter_values.meta_key = null;
            getTalents(filter_values, filtresCategories)
            message.error('please put a talent name')
        }
    };

    return (
        <div>
            <Form
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
            >
                <Form.Item style={{margin: "0 0 0 95%"}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            name="compain_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign Name!',
                                },
                            ]}
                        >
                            <Input placeholder="Compaign Name"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign Description!',
                                },
                            ]}
                        >
                            <TextArea rows={4} placeholder="Description"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name="start_date_at"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign Start Date!',
                                },
                            ]}
                        >
                            <DatePicker className="gx-mb-3 gx-w-100" placeholder="Start date"/>
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24} className={"ml-2"}>
                        <Form.Item
                            name="end_date_at"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign End Date!',
                                },
                            ]}
                        >
                            <DatePicker className="gx-mb-3 gx-w-100" placeholder="End date"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name="url"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign Url!',
                                },
                            ]}
                        >
                            <Input placeholder="Compaign Url"/>
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24} className={"ml-2"}>
                        <Form.Item
                            name="visit_price"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign visit price!',
                                },
                            ]}
                        >
                            <Input placeholder="Compaign visit price"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} md={6} sm={24} xs={24} className={"ml-2"}>
                        <Form.Item
                            label={"Compaign Image"}>
                            <div className={"group-file-upload"}>
                                <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={handleUploadClick}
                                />
                                <Button icon={<UploadOutlined/>} onClick={() => {
                                    document.getElementById('contained-button-file').click()
                                }}>Click to upload</Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                {compain_efile_id ?
                    <Row>
                        <Card title="Compain Picture" className="gx-card clearfix ml-2">
                            <Upload
                                listType="picture-card"
                                fileList={compainImage}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                            </Upload>
                            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                <img alt="example" style={{width: '100%'}} src={previewPicture}/>
                            </Modal>
                        </Card>
                    </Row> : ''
                }
            </Form>
            <Widget
                title={
                    <h2 className="h4 gx-text-capitalize gx-mb-0">
                        List Talents Invited</h2>
                }>
                <div className="gx-table-responsive">
                    <Table className="gx-table-no-bordered" columns={InfoTalentsInvitedColumns}
                           dataSource={TalentsInvited}
                           pagination={true}
                           size="small"/>
                </div>
            </Widget>
            <Card className="gx-card" title="Advanced Search For Talents">
                <Form
                    form={formFilter}
                    name="advanced_search_talents"
                    className="ant-advanced-search-talents-form"
                    onFinish={onFiltre}
                >
                    <Row gutter={24}>{getFieldsSearch()}</Row>
                    <Row>
                        <Col span={24} style={{textAlign: 'right'}}>
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                            <Button
                                onClick={() => {
                                    resetFilter()
                                }}
                            >
                                Clear
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Widget
                title={
                    <h2 className="h4 gx-text-capitalize gx-mb-0">
                        List Talents</h2>
                }>
                <Search
                    placeholder="input talent name"
                    onSearch={value => onFiltreTalentsByCode(value)}
                    style={{width: 200}}
                />
                <div className="gx-table-responsive">
                    <Table className="gx-table-no-bordered" columns={InfoTalentsColumns} dataSource={Talents}
                           pagination={true}
                           size="small"/>
                </div>
            </Widget>
        </div>
    );
};

export default AddPrivateCampaign;


