import React, {Fragment, useEffect} from "react";
import {Alert, Card, Col, Input, message, Row, Spin} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import CollaborationsPublic from "./CollaborationsPublic";
import compainApiService from "../../../../util/services/CompainApiService";
import IntlMessages, {translateMsg} from "../../../../util/IntlMessages";
import {useIntl} from "react-intl";
import talentApiService from "../../../../util/services/TalentsApiService";
import SweetAlert from "react-bootstrap-sweetalert";
import {isMyAccount} from "../../../../util/helpers";
import FBValidatorConnector from "../../../../components/FBComponents/FBValidatorAccount";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Pagination from "@material-ui/lab/Pagination";
import '../../../../assets/scss/App.scss'
import Loader from "../../../compain-manager/loader";

const Search = Input.Search;

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}));
const PublicCollaboration = (props) => {
    const classes = useStyles();
    const [publicCollaborations, setPublicCollaborations] = React.useState([]);
    const talent_id = localStorage.getItem('talent_id') ? localStorage.getItem('talent_id') : null;
    const [talent, setTalent] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [showAlert, setShowAlert] = React.useState(true);
    const [pages, setPages] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [meta_key, setMeta_key] = React.useState('');
    const [is_valid, setIsValid] = React.useState(localStorage.getItem('is_valid') || null);
    const intl = useIntl();

    useEffect(() => {
        getPublicCompains(page)
    }, [talent_id]);

    const getPublicCompains = (page) => {
        compainApiService
            .getPublicCompainInfo({
                talent_id: talent_id,
                limit: 10,
                page: page,
            })
            .then(res => {
                setIsLoading(false);
                setPublicCollaborations(res.data.data || null);
                setPages(res.data.pages);
                setOffset(res.data.offset);
                setCount(res.data.count)
            });
    }

    useEffect(() => {
        talentApiService
            .getMe()
            .then(res => {
                setTalent(res.data.data.profile || null);
            });
    }, [talent_id]);

    const callbackFBConnector = (data) => {
        setTalent({...talent, ...{is_valid: 'Y'}});
        message.success(translateMsg("AccountValidatedWithSuccess", intl), 3, () => {
            window.location.reload();
        });
    };
    const disableAlert = () => {
        setShowAlert(false)
    };
    const onChangePage = (event, newPage) => {
        let current_page = newPage
        setPage(current_page)
        getPublicCompains(current_page)
    };

    const onFiltreCompainsByCode = (code) => {
        let filter = [];
        filter.push({
            operator: 'and',
            conditions: [
                {
                    field: 'category',
                    operator: 'eq',
                    value: 'public'
                }
            ]
        });
        setMeta_key(code)
        if (code && code !== '' && code.toString().length > 3) {
            compainApiService
                .find({
                    limit: -1,
                    includes: ['advertisers'],
                    meta_key: code.toString(),
                    filter: filter
                })
                .then(res => {
                    setIsLoading(false);
                    setPublicCollaborations(res.data.data || null);
                    setPages(res.data.pages);
                    setOffset(res.data.offset);
                    setCount(res.data.count)
                });
        } else {
            getPublicCompains(1)
        }
    };

    return (
        <div>
            {!isLoading ?
                <>
                    {is_valid === 'Y' ?
                        <Fragment>
                          <Search
                              className="gx-ml-3"
                              enterButton="Search" size="large"
                              placeholder="input compain name"
                              onSearch={value => onFiltreCompainsByCode(value)}
                              style={{width: 500}}
                          />
                          {/*{publicCollaborations && publicCollaborations.length !== 0 ?*/}
                            <Auxiliary>
                                <Row>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <CollaborationsPublic publicCollaborations={publicCollaborations}
                                                              pages={pages}/>
                                    </Col>
                                </Row>
                                <Pagination style={{float: "right"}} count={pages} color="primary"
                                            onChange={onChangePage}/>
                            </Auxiliary>
                              {/*  :*/}
                              {/*// <div className="gx-page-error-container">*/}
                              {/*//   <div className="gx-page-error-content">*/}
                              {/*//     <h2 className="gx-text-center">*/}
                              {/*//       <IntlMessages id="public_collaboration.notfound"/></h2>*/}
                              {/*//   </div>*/}
                              {/*// </div>*/}
                              {/*<Card className="gx-card" title="Basic"/>*/}

                              {/*}*/}
                        </Fragment>
                        : <SweetAlert show={showAlert}
                                      showCancel={false}
                                      showConfirm={false}
                                      title={<IntlMessages id="ad.accountNotValid"/>}
                        >
                            {talent?.is_valid !== "Y" && isMyAccount(talent) ?
                                <div className={"gx-mb-3"} style={{textAlign: "center"}}>
                                    <p>Connectez-vous en utilisant Instagram pour vérifier votre compte</p>
                                    <Button onClick={disableAlert}>
                                        <FBValidatorConnector profileType={"talent"} callbackFBConnector={(data) => {
                                            callbackFBConnector(data);
                                        }}/>
                                    </Button>
                                </div> : ''}
                        </SweetAlert>}
                </> :
                <Loader/>
            }
        </div>
    );
};

export default PublicCollaboration;
