import React from "react";
import {Layout} from "antd";

import Sidebar from "../../tawa-digital-core/src/containers/Sidebar/index";
import HorizontalDefault from "../../tawa-digital-core/src/containers/Topbar/HorizontalDefault/index";
import HorizontalDark from "../../tawa-digital-core/src/containers/Topbar/HorizontalDark/index";
import InsideHeader from "../../tawa-digital-core/src/containers/Topbar/InsideHeader/index";
import AboveHeader from "../../tawa-digital-core/src/containers/Topbar/AboveHeader/index";
import BelowHeader from "../../tawa-digital-core/src/containers/Topbar/BelowHeader/index";

import Topbar from "../../tawa-digital-core/src/containers/Topbar";
import  siteConfig from "../../tawa-digital-core/src/config/site.config";
import App from "../../tawa-digital-core/src/routes/index";
import {useSelector} from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../tawa-digital-core/src/constants/ThemeSetting";
import NoHeaderNotification from "../../tawa-digital-core/src/containers/Topbar/NoHeaderNotification/index";
import {useRouteMatch} from "react-router-dom";
import Customizer from "../../tawa-digital-core/src/containers/Customizer.js";

const {Content, Footer} = Layout;


const MainApp = () => {
  const {navStyle} = useSelector(({settings}) => settings);
  const match = useRouteMatch();

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL :
        return <HorizontalDefault/>;
      case NAV_STYLE_DARK_HORIZONTAL :
        return <HorizontalDark/>;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL :
        return <InsideHeader/>;
      case NAV_STYLE_ABOVE_HEADER :
        return <AboveHeader/>;
      case NAV_STYLE_BELOW_HEADER :
        return <BelowHeader/>;
      case NAV_STYLE_FIXED :
        return <Topbar/>;
      case NAV_STYLE_DRAWER :
        return <Topbar/>;
      case NAV_STYLE_MINI_SIDEBAR :
        return <Topbar/>;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
        return <NoHeaderNotification/>;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR :
        return <NoHeaderNotification/>;
      default :
        return null;
    }
  };


  return (
    <Layout className="gx-app-layout">
     <Sidebar/>
     <Layout>
       {getNavStyles(navStyle)}
       <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
         <App match={match} baseUrl={siteConfig.AdminUrl}/>
         <Footer>
           <div className="gx-layout-footer-content">
             {siteConfig.footerText}
           </div>
         </Footer>
       </Content>
     </Layout>
    </Layout>
  )
};
export default MainApp;

