import React, { useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { Button, Card, Checkbox, Form, Input, Modal, Radio, Upload } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import BaseApiService from "../../../../../util/BaseApiService";
import IntlMessages, { translateMsg } from "../../../../../util/IntlMessages";
import Select from 'react-select';
import categoriesApiService from "../../../../../util/services/CategoriesApiService";
import talentApiService from "../../../../../util/services/TalentsApiService";
import { message } from "antd/lib/index";
import { useIntl } from "react-intl";
import { post } from "axios";
import siteConfig from "../../../../../config/site.config";
import { UploadOutlined } from "@ant-design/icons";
import { tawaAge } from "../../../../../util/gender";
import { Col, Row } from "reactstrap";
import { countryApiService } from "../../../../../util/services/CountryService";

const baseApiService = new BaseApiService();
const FormItem = Form.Item;
const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

const UpdateTalentProfile = ({ enable_update, disableDrawerUpdate, meta_key, talent_id, current_page }) => {
    const [talent, setTalent] = React.useState({});
    const [user, setUser] = React.useState({});
    const [form] = Form.useForm();
    const [selectedVille, setSelectedVille] = React.useState('');
    const [selectedGender, setSelectedGender] = React.useState(null);
    const [age, setAge] = React.useState('');
    const [categories, setCategories] = React.useState([]);
    const [selectCategories, setSelectedCategories] = React.useState([]);
    const [updateIgUsername, setUpdateIgUsername] = React.useState(false);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewPicture, setPreviewPicture] = React.useState('');
    const [talent_efile_id, setTalent_efile_id] = React.useState();
    const [selectedCountryOfOrigin, setSelectedCountryOfOrigin] = React.useState(null);
    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const [citiesOptions, setCitiesOptions] = React.useState([]);
    const [countries, setCountries] = React.useState([]);
    const [ageOptions, setAgeOptions] = React.useState([]);
    const intl = useIntl();

    useEffect(() => {
        getTalentInfo()

        setAgeOptions(tawaAge.map(item => ({
            value: item.value,
            label: item.label
        })));

        categoriesApiService.find({ limit: -1 }).then(res => {
            setCategories(res.data.data || []);
        });

        countryApiService.find().then(res => {
            const { data } = res.data
            const newCountryList = data.map(item => ({
                value: item.isoCode,
                label: item.name
            }))
            setCountries(newCountryList)
        })
    }, [])

    const handleChangeCountryOfOrigin = (formItem) => {
        setSelectedCountryOfOrigin(formItem);

        form.setFieldsValue({
            country_of_origin: formItem.value || null
        })
    };

    const [state, setState] = React.useState({
        right: enable_update,
    });

    const useStyles = makeStyles({
        list: {
            width: 1000,
            maxWidth: '100%',
        },
        fullList: {
            width: 'auto',
        },
    });

    const classes = useStyles();

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const getTalentInfo = () => {
        talentApiService.findById(talent_id)
            .then(res => {
                baseApiService.getUserByProfile({ talent_id: talent_id, profile: 'talent' }).then(data_user => {
                    const dataUser = data_user.data.data;
                    setUser(dataUser || null);
                    const talentData = res.data.data;
                    setTalent(res.data.data || null);
                    setTalent_efile_id(talentData?.profile_efile_id);

                    if (talentData?.ville && talentData?.country) {
                        // let gv_country = getCountryCities(talentData?.country);
                        // console.log(gv_country)
                        // let ville_data = gv_country.filter(item => item.label === talentData?.ville);
                        // setSelectedVille(ville_data && ville_data.length !== 0 ? ville_data[0]: null);
                    }

                    /*if (talentData?.country && countries.length !== 0) {
                        let cn_data = countries.filter(item => item.isoCode === talentData?.country);
                        setSelectedCountry(cn_data && cn_data.length !== 0 ? { value: cn_data[0].isoCode, label: cn_data[0].name } : null);
                    }*/

                    if (talentData?.country_of_origin && countries.length !== 0) {
                        let cn_of_or_data = countries.filter(item => item.isoCode === talentData?.country_of_origin);
                        setSelectedCountryOfOrigin(cn_of_or_data && cn_of_or_data.length !== 0 ? { value: cn_of_or_data[0].isoCode, label: cn_of_or_data[0].name } : null);
                    }

                    if (talentData?.age_intervale) {
                        let ag_data = tawaAge.filter(item => item.value === talentData?.age_intervale);
                        setAge(ag_data && ag_data.length !== 0 ? ag_data[0] : null);
                    }

                    if (talentData?.gender) {
                        setSelectedGender(talentData?.gender);
                    }

                    setSelectedCategories(talentData?.categories)

                    if (talentData && talentData.is_valid === 'Y') {
                        setUpdateIgUsername(true)
                    }
                })
            });
    };

    const handleChangeVille = (value) => {
        setSelectedVille(value || null);
        form.setFieldsValue({
            ville: value?.value || null
        })
    };

    const handleChangeCountry = (formItem) => {
        setSelectedCountry(formItem);

        form.setFieldsValue({
            country: formItem.value || null
        })

        if (formItem.value) {
            form.setFieldsValue({
                country: formItem?.value || null
            })

            let allCitites = []
            countryApiService.getStates(formItem.value).then((res) => {
                const { data } = res.data
                const newStates = data.map(state => { return { value: state.name, label: state.name } })
                allCitites = [...allCitites, ...newStates]
                setCitiesOptions(allCitites);
            })
            setSelectedVille(null);
        } else {
            setCitiesOptions([]);
            setSelectedVille(null);
        }
    };

    const handleUploadClick = event => {
        const file = event.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            return post(siteConfig.AppConfig.fileUploadUrl, formData, {
                headers: {
                    'Content-Type': `multipart/form-data;`,
                },
                timeout: 30000,
            }).then(res => {
                setTalent_efile_id(res.data.data)
            })
        }
    };

    const handleCancel = () => {
        setPreviewVisible(false)
    }

    const handlePreview = (file) => {
        setPreviewPicture(file.url || file.thumbUrl)
        setPreviewVisible(true)
    };

    const talentPicture = [{
        uid: -1,
        name: 'talent picture',
        status: 'done',
        url: siteConfig.AppConfig.baseStyleImageUrl + talent_efile_id,
    }]

    const handleChange = () => {
        talent_efile_id(null)
    }

    useEffect(() => {
        if (talent?.country && countries.length !== 0) {
            const countryOption = countries.find(item => item.value === talent?.country);
            setSelectedCountry(countryOption);
            handleChangeCountry(countryOption)
        }
        if (talent?.country_of_origin && countries.length !== 0) {
            const countryOption = countries.find(item => item.value === talent?.country_of_origin);
            setSelectedCountryOfOrigin(countryOption);
        }
    }, [talent, countries])

    useEffect(() => {
        if (talent?.ville && citiesOptions.length !== 0) {
            const cityOption = citiesOptions.find(item => item.value === talent?.ville);
            setSelectedVille(cityOption);
        }
    }, [talent, citiesOptions])

    useEffect(() => {
        if (!talent || !talent.influencer_id) {
            return
        }

        const formData = { ...talent, ...{} };
        form.setFieldsValue(formData);

        form.setFieldsValue({
            user_email: user?.user_email || formData?.user_email,
            phone: formData?.phone,
            ville: formData?.ville,
            country_of_origin: formData?.country_of_origin,
            country: formData?.country,
            gender: formData?.gender,
            age: formData?.age_intervale,
            categories: formData?.categories,
            ig_username: formData?.ig_username,
        });
    }, [talent]);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            disableDrawerUpdate(false, meta_key, current_page)
            return;
        }
        setState({ ...state, [anchor]: open });
        disableDrawerUpdate(false, meta_key, current_page)
    };

    const onFinish = values => {
        const formProfile = values
        formProfile.categories = selectCategories;
        formProfile.ville = selectedVille?.label || null;
        formProfile.country = selectedCountry?.value || null;
        formProfile.country_of_origin = selectedCountryOfOrigin?.value || null;
        formProfile.gender = selectedGender;
        formProfile.age = age.value;
        formProfile.influencer_id = talent_id;
        formProfile.profile_efile_id = talent_efile_id;

        talentApiService.updateTalentInfo(formProfile).then(res => {
            message.success('Account updated with success', 3)
            disableDrawerUpdate(false, meta_key, current_page);
        }).catch(err => {
            const { data } = err.response;
            if (data && data.messages) {
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    };

    const handleChangeAge = (formItemData) => {
        setAge(formItemData);
        form.setFieldsValue({
            age_intervale: formItemData.value || null
        })
    };

    const handleChangeCategories = (selected_categories) => {
        setSelectedCategories(selected_categories);
    };

    const FormProfile = (anchor) => {
        return (<div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
            <Card className="gx-card" title="Update Profile">
                <Form
                    {...formItemLayout}
                    form={form}
                    name="update"
                    onFinish={onFinish}
                    initialValues={{
                        prefix: '216',
                    }}
                    scrollToFirstError
                    autoComplete={"off"}
                >
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your user name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="first_name"
                        label="Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your First name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        label="Family Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your Last name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="contact_tel"
                        label="Phone Number"
                        rules={[{ required: false, message: 'Please input your phone number!' }]}
                    >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="user_email"
                        label="Email"
                        rules={[{ required: false, message: 'Please input your Email!' }]}
                    >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="ig_username"
                        label="Instagram user name"
                    >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="tiktok_username"
                        label="Tiktok user name"
                    >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <FormItem
                        name="country_of_origin"
                        label="Country of origin"
                        rules={[{ required: true, message: 'Please select your Country of origin!' }]}
                    >
                        <Row>
                            <Col md='6'>
                                <Select
                                    value={selectedCountryOfOrigin}
                                    theme={selectThemeColors}
                                    onChange={(value) => handleChangeCountryOfOrigin(value)}
                                    options={countries}
                                />
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem name="country"
                        label="Country of residence"
                        rules={[{ required: true, message: 'Please select your Country of residence!' }]}>
                        <Row>
                            <Col md='6'>
                                <Select
                                    value={selectedCountry}
                                    theme={selectThemeColors}
                                    onChange={(value) => handleChangeCountry(value)}
                                    options={countries}
                                />
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem name="ville"
                        label="City"
                        rules={[{ required: false, message: 'Please select your city!' }]}>
                        <Row>
                            <Col md='6'>
                                <Select
                                    isClearable={true}
                                    theme={selectThemeColors}
                                    value={selectedVille}
                                    onChange={(value) => handleChangeVille(value)}
                                    options={citiesOptions}
                                />
                            </Col>
                        </Row>
                    </FormItem>

                    <Form.Item
                        name="gender"
                        label="Gender"
                        rules={[{ required: false, message: 'Please select your gender!' }]}
                    >
                        <div className={"mb-3"}>
                            <Radio.Group onChange={(e) => setSelectedGender(e.target?.value || null)} value={selectedGender}>
                                <Radio value={null}>Prefer not to say</Radio>
                                <Radio value={"M"}><IntlMessages id="homme" /></Radio>
                                <Radio value={"F"}><IntlMessages id="femme" /></Radio>
                            </Radio.Group>
                        </div>
                    </Form.Item>

                    <FormItem
                        name="age_intervale"
                        label="Age"
                        rules={[{ required: false, message: 'Please select your age interval!' }]}
                    >
                        <Row>
                            <Col md='6'>
                                <Select
                                    theme={selectThemeColors}
                                    onChange={(value) => handleChangeAge(value)}
                                    value={age}
                                    options={ageOptions}
                                />
                            </Col>
                        </Row>
                    </FormItem>

                    <Form.Item
                        name="categories"
                        label="Categories"
                        rules={[{ required: true, message: 'Please select your Categories!' }]}
                    >
                        <Checkbox.Group onChange={handleChangeCategories}
                            defaultValue={categories}>
                            {categories.map(option => {
                                return <Checkbox className={"gx-ml-0 gx-mr-1 gx-mb-1"} key={option.value}
                                    value={option.category_id}>{option.label}</Checkbox>
                            })}
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item
                        name="passwordhash"
                        label="Password"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your password!',
                            },
                        ]}
                        autoComplete={"new-password"}
                        hasFeedback
                    >
                        <Input.Password autoComplete={"new-password"} />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['passwordhash']}
                        hasFeedback
                        rules={[
                            {
                                required: false,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if ((!value && !getFieldValue('passwordhash')) || getFieldValue('passwordhash') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password autoComplete={"new-password"} />
                    </Form.Item>
                    <Form.Item
                        label={"Talent picture"}>
                        <div className={"group-file-upload"}>
                            <input
                                accept="image/*"
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleUploadClick}
                            />
                            <Button icon={<UploadOutlined />} onClick={() => {
                                document.getElementById('contained-button-file').click()
                            }}>Click to upload</Button>
                        </div>
                    </Form.Item>
                    {talent_efile_id ?
                        <Card title="Talent picture" className="gx-card clearfix">
                            <Upload
                                listType="picture-card"
                                fileList={talentPicture}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                            </Upload>
                            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                <img alt="Talent picture" style={{ width: '100%' }} src={previewPicture} />
                            </Modal>
                        </Card> : ''
                    }

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            Update
                        </Button>
                        <Button type="secondary" onClick={() => disableDrawerUpdate(false, meta_key, current_page)}><IntlMessages
                            id="drawer.cancel" /></Button>
                    </Form.Item>
                </Form>
            </Card>

        </div>
        );
    };

    return (
        <Drawer anchor={'right'} open={state['right']}
            onClose={toggleDrawer('right', false)}>
            {FormProfile('right')}
        </Drawer>
    )
};

export default UpdateTalentProfile;
