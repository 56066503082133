import axios from "axios";
import {apiUrl} from "../config/site.config";

class BaseApiService {
    constructor(entity_name = '') {
        this.entity_name = entity_name;
    }

    find(params = {}) {
        const params_encoded = (params && Object.keys(params).length) ? encodeURIComponent(JSON.stringify(params)) : '';
        return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/find/' + params_encoded);
    }

    findById(entity_id) {
        return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/findById/' + entity_id);
    }

    save(data) {
        return this.setHeaderToken().post(apiUrl + '/' + this.entity_name + '/register', data);
    }

    update(data) {
        console.log(data)
        return this.setHeaderToken().put(apiUrl + '/' + this.entity_name + '/update', data);
    }

    delete(params = {}) {
        const params_encoded = (params && Object.keys(params).length) ? encodeURIComponent(JSON.stringify(params)) : '';
        return this.setHeaderToken().delete(apiUrl + '/' + this.entity_name + '/delete/' + params_encoded);
    }

    signin(data) {
        return axios.post(apiUrl + '/' + this.entity_name, data);
    };

    getAxiosInst() {
        return undefined;
    };

    setHeaderToken() {
        return axios.create({
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        });
    };

    getMe() {
        return this.setHeaderToken().get(apiUrl + '/user/getMe');
    };

    updateUser(data) {
        return this.setHeaderToken().post(apiUrl + '/user/UpdateUser', data);
    };

    addTalent(data) {
        return this.setHeaderToken().post(apiUrl + '/user/addTalent', data);
    };
    deleteUserByADid(data) {
        return this.setHeaderToken().post(apiUrl + '/user/deleteUserByADid', data);
    };

    getUserByProfile(data) {
        return this.setHeaderToken().post(apiUrl + '/user/getUserByProfile', data);
    };
    updateMyPictures(data) {
        return this.setHeaderToken().post(apiUrl + '/user/updateMyPictures', data);
    };

    addAd(data) {
        return this.setHeaderToken().post(apiUrl + '/user/addAdvertisor', data);
    };
    addAg(data) {
        return this.setHeaderToken().post(apiUrl + '/user/addAgency', data);
    };

    getAllManagers(params = {}) {
        const params_encoded = (params && Object.keys(params).length) ? encodeURIComponent(JSON.stringify(params)) : '';
        return this.setHeaderToken().get(apiUrl + '/user/find/' + params_encoded);
    };

    findUserById(entity_id) {
        return this.setHeaderToken().get(apiUrl + '/user/findById/' + entity_id);
    };

    deleteUser(params = {}) {
        const params_encoded = (params && Object.keys(params).length) ? encodeURIComponent(JSON.stringify(params)) : '';
        return this.setHeaderToken().delete(apiUrl + '/user/delete/' + params_encoded);
    };

    addManager(data) {
        return this.setHeaderToken().post(apiUrl + '/user', data);
    };

    stats(params) {
        return this.setHeaderToken().post(apiUrl + '/dashboard/stats', params);
    };
    verifyToken(params) {
        return this.setHeaderToken().post(apiUrl + '/user/verifyToken', params);
    }
    sendMailConfirmationAgency(data) {
        return this.setHeaderToken().post(apiUrl + '/user/sendMailConfirmationAgency', data);
    }
}

export default BaseApiService;
