const getEnvConfig = () => {
  return process.env;
};
const Backend_Server = getEnvConfig().REACT_APP_API_BASE_URL;
const App_Base_URL = getEnvConfig().REACT_APP_BASE_URL;
const Site_Url = getEnvConfig().REACT_APP_SITE_BASE_URL;

const siteConfig =  {
  backendServer: Backend_Server,
  appBaseUrl: App_Base_URL,
  siteName: 'TAWA Digital @',
  siteIcon: 'ion-flash',
  footerText: `TAWA Digital @ ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  apiUrl: getEnvConfig().REACT_APP_API_BASE_URL,
  loginUrl: getEnvConfig().REACT_APP_LOGIN_URL,
  TalentUrl: getEnvConfig().REACT_APP_TALENT_BASE_URL,
  profileAdminId: parseInt(getEnvConfig().REACT_APP_PROFILE_ADMIN_ID) || 1,
  profileTalentId: parseInt(getEnvConfig().REACT_APP_PROFILE_TALENT_ID) || 2,
  profileAdvertiserId: parseInt(getEnvConfig().REACT_APP_PROFILE_ADVERTISER_ID) || 3,
  profileAgencyId: parseInt(getEnvConfig().REACT_APP_PROFILE_AGENCY_ID) || 4,
  ADUrl: getEnvConfig().REACT_APP_AD_BASE_URL,
  SignUpAdUrl: getEnvConfig().REACT_APP_SIGNUP_AD_URL,
  SignUpAgUrl: getEnvConfig().REACT_APP_SIGNUP_AG_URL,
  SignUpTalentUrl: getEnvConfig().REACT_APP_SIGNUP_TALENT_URL,
  IsTalent: getEnvConfig().REACT_APP_TALENT_SPACE,
  IsAD: getEnvConfig().REACT_APP_AD_SPACE,
  IsAdmin: getEnvConfig().REACT_APP_ADMIN_SPACE,
  AdminUrl: getEnvConfig().REACT_APP_ADMIN_BASE_URL,
  IsAgency: getEnvConfig().REACT_APP_AGENCY_SPACE,
  AgencyUrl: getEnvConfig().REACT_APP_AGENCY_BASE_URL,
  Max_request: 5000,
  Max_request_eng: 5000,

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  prefixMe: '/me',
  facebook: {
    app_id: getEnvConfig().REACT_APP_FB_APP_ID,
    graph_api_url: 'https://graph.facebook.com/v9.0',
    validation_post_code: 'CLmSrrljQY0',
    validation_post_id: 2514779608508597812,
  },
  dashboard: '/crm',
  AppConfig:{
    fileUploadUrl: Backend_Server +  '/uploadFile',
    baseStyleImageUrl: Backend_Server + '/file/thumb/full/',
    recordUploadUrl: Backend_Server +  '/upload',
    baseRecordUrl: Backend_Server + '/file/record/',
    // apiBaseRedirectUrl: Backend_Server +'/r/',
    apiBaseRedirectUrl: Site_Url +'/r/',
  }
};


export const apiUrl = siteConfig.apiUrl;
export const fbApiUrl = siteConfig.facebook.graph_api_url;


export default siteConfig;
