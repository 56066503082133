import React, {useEffect} from "react";
import {Card, Col, Input, message, Row} from "antd";
import advertiserApiService from "../../util/services/AdvertisersApiService";
import IntlMessages, {translateMsg} from "../../util/IntlMessages";
import {useIntl} from "react-intl";
import IgAccountInfo from "../../components/Widgets/IgAccountInfo";
import SweetAlert from "react-bootstrap-sweetalert";
import {isMyAccount} from "../../util/helpers";
import FBValidatorConnector from "../../components/FBComponents/FBValidatorAccount";
import Button from "@material-ui/core/Button";
import '../../assets/scss/App.scss'

const Search = Input.Search;

const EngagementInfo = () => {
    const [igAccountInfo, setIgAccountInfo] = React.useState(null);
    const [igUserName, setIgUserName] = React.useState(null);
    const [advertiser, setAdvertiser] = React.useState(null);
    const ad_id = localStorage.getItem('ad_id') ? localStorage.getItem('ad_id') : null
    const [showAlert, setShowAlert] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [is_valid, setIsValid] = React.useState(localStorage.getItem('is_valid') || null);
    const intl = useIntl();
    const customSearch = ((ig_info) => {
        let ig = 'https://www.instagram.com/';
        let ig_M = 'https://instagram.com';
        let src = 'hl=fr';
        let srcMobile ='utm_medium=copy_link'
        let specific_car = '/';
        let data = ig_info.replace(ig, '');
        let dataM = data.replace(ig_M, '');
        let data_src = dataM.replace(src, '');
        let ig_code = data_src.replace(specific_car, '');
        let ig_code_f = ig_code.replace('?','');
        let ig_code_f_M = ig_code_f.replace(srcMobile,'');
        let ig_code_ = ig_code_f_M.replace(' ', '')
        setIgUserName(ig_code_);
    });

    useEffect(() => {
        if (igUserName && igUserName !== '') {
            setIgAccountInfo(null)
            setLoading(true)
            let formData = {
                ig_username: igUserName
            }
            advertiserApiService
                .getIGAccountInfo(formData)
                .then(res => {
                    setIgAccountInfo(res.data.data);
                    setLoading(false);
                }).catch(err => {
                if (err.response.data && err.response.data.messages) {
                    setIgAccountInfo(null);
                    setLoading(false);
                    err.response.data.messages.forEach(msg => {
                        message.error(translateMsg(msg, intl));
                    })
                }
            });
        } else {
            setIgAccountInfo(null);
        }
    }, [igUserName]);

    useEffect(() => {
        advertiserApiService
            .getMe(ad_id)
            .then(res => {
                setAdvertiser(res.data.data.profile || null);
            });
    }, [ad_id]);

    const callbackFBConnector = (data) => {
        setAdvertiser({...advertiser, ...{is_valid: 'Y'}});
        message.success(translateMsg("AccountValidatedWithSuccess", intl), 3, () => {
            window.location.reload();
        });
    };

    const disableAlert = () => {
        setShowAlert(false)
    };

    return (
        <>
            {is_valid === 'Y' || advertiser?.profile_id === 1 ?
                <Card className="gx-card" title="Engagement rate calculator">
                    <>
                        <Search
                            placeholder="Enter instagram profile"
                            onSearch={value => customSearch(value)}
                            enterButton
                        />
                        {igAccountInfo ?
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                    <IgAccountInfo igAccountInfo={igAccountInfo}/>
                                </Col>
                            </Row> : ''
                        }
                        {loading ?
                            <div id="preloader-wrapper" style={{borderColor:'#ffffff'}}>
                                <div className="preloader-container">
                                    <div className="cssload-loader">
                                        <div className="cssload-inner cssload-one" style={{borderColor: '#fd67af'}}/>
                                        <div className="cssload-inner cssload-two" style={{borderColor:'#9201fe'}}/>
                                        <div className="cssload-inner cssload-three" style={{borderColor:'#49cfff'}}/>
                                    </div>
                                </div>
                            </div> :''
                        }
                    </>
                </Card> :
                <SweetAlert show={showAlert}
                            showCancel={false}
                            showConfirm={false}
                            title={<IntlMessages id="ad.accountNotValid"/>}
                >
                    {advertiser?.is_valid !== "Y" && isMyAccount(advertiser) ?
                        <div className={"gx-mb-3"} style={{textAlign: "center"}}>
                            <p>Connectez-vous en utilisant Instagram pour vérifier votre compte</p>
                            <Button onClick={disableAlert}>
                                <FBValidatorConnector profileType={"advertiser"} callbackFBConnector={(data) => {
                                    callbackFBConnector(data);
                                }}/>
                            </Button>
                        </div> : ''}
                </SweetAlert>
            }
        </>

    );
};

export default EngagementInfo;
