
const ages = [
  {
    value: '18-24',
    label: '18 - 24'
  },
  {
    value: '25-34',
    label: '25 - 34'
  },

  {
    value: '35-44',
    label: '35 - 44',
  },
  {
    value: '45-60',
    label: '45 - 60'
  }
];

export const tawaAges = ages;
