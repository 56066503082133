import React, {useEffect} from "react";
import {Card, Divider, message, Popconfirm, Table} from "antd";
import Pagination from "@material-ui/lab/Pagination";
import categoriesApiService from "../../../../util/services/CategoriesApiService";
import '../../../../assets/scss/App.scss'
import AddCategory from "./add-category";
import UpdateCategory from "./update-category";

const Categories = () => {
    const [categories, setCategories] = React.useState([]);
    const [initApp, setInitApp] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pages, setPages] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [showDrawerAdd, setShowDrawerAdd] = React.useState(false);
    const [showDrawerUpdate, setShowDrawerUpdate] = React.useState(false);
    const [category_id, setCategory_id] = React.useState(false);

    const columns = [
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Code',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
      <span className="gx-link" onClick={() => EnableDrawerUpdateCategory(record)}>Update</span>
      <Divider type="vertical"/>
      <Popconfirm title="Are you sure delete this category?" onConfirm={() => confirm(record)}
                  onCancel={cancel} okText="Yes"
                  cancelText="No">
      <span className="gx-link">Delete</span>
            </Popconfirm>
      <Divider type="vertical"/>
    </span>
            ),
        }
    ];

    useEffect(() => {
        getAllCategories(page)
    }, [initApp]);

    const getAllCategories = (current_page) => {
        categoriesApiService.find({
            limit: 10,
            page: current_page,
        }).then(res => {
            setIsLoading(false);
            setCategories(res.data.data || []);
            setPages(res.data.attributes.pages);
            setOffset(res.data.attributes.offset);
            setCount(res.data.attributes.count)
        });
    };

    const onChangePage = (event, newPage) => {
        getAllCategories(newPage)
    };

    function confirm(data) {
        categoriesApiService.delete({id: data.category_id}).then(res => {
            message.success('Category deleted wth success');
            getAllCategories(1)
        })
    }

    function cancel(e) {
    }

    const EnableDrawerAdd = () => {
        setShowDrawerAdd(true)
    }
    const disableDrawerAdd = (event) => {
        setShowDrawerAdd(event);
        getAllCategories(1)
    };

    const disableDrawerUpdate = (event) => {
        setShowDrawerUpdate(event);
        getAllCategories(1)
    };

    const EnableDrawerUpdateCategory = (data) => {
        setCategory_id(data.category_id)
        setShowDrawerUpdate(true)
    };

    return (
        <>
            {!isLoading ?
                <Card>
                    <div className={"gx-mb-3"} style={{textAlign: "right"}}>
                        <button className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-text-uppercase "
                                onClick={EnableDrawerAdd}>
                            Add
                        </button>
                    </div>
                    <Table className="gx-table-responsive" columns={columns} dataSource={categories}
                           pagination={false}/>
                    {showDrawerAdd ?
                        <AddCategory enable_add={showDrawerAdd} disableDrawerAdd={disableDrawerAdd}/> : ''
                    }
                    {showDrawerUpdate ?
                        <UpdateCategory enable_update={showDrawerUpdate} disableDrawerUpdate={disableDrawerUpdate}
                                        category_id={category_id}/> : ''
                    }
                    <Pagination style={{float: "right"}} count={pages} color="primary" onChange={onChangePage}/>
                </Card> :
                <div id="preloader-wrapper" style={{borderColor: '#ffffff'}}>
                    <div className="preloader-container">
                        <div className="cssload-loader">
                            <div className="cssload-inner cssload-one" style={{borderColor: '#fd67af'}}/>
                            <div className="cssload-inner cssload-two" style={{borderColor: '#9201fe'}}/>
                            <div className="cssload-inner cssload-three" style={{borderColor: '#49cfff'}}/>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Categories;
