import React, { useEffect, useState } from "react";
import { Card, Table, Typography, Dropdown, Menu, Row, Button, Form, Input, Col, Modal, Select, notification } from "antd";
import privateUserApiService from "../../util/services/v2/PrivateUserApiService";
import moment from "moment";
import { find } from "lodash";
const { Text } = Typography;

const AdminManageUsers = (props) => {

    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState({ q: null, page: 1 });
    const [isValidateModalOpen, setIsValidateModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [formValidateAccount] = Form.useForm();
    const [apiNotification, contextHolder] = notification.useNotification();

    const getUsers = () => {
        setUsers([]);
        privateUserApiService.find({
            page: filters.page || 1,
            q: filters.q || '',
            is_verified: filters?.is_verified,
            is_completed: filters?.is_completed,
            is_validated: filters?.is_validated,
            role: filters?.role,
        }).then(result => {
            const { data } = result;
            const { items, attributes } = data;
            items.map(item => item.key = item.user_id)
            const { limit, count } = attributes;
            // setTotal(Math.ceil(count / limit));
            setTotal(count);
            setUsers(items);
        });
    };

    useEffect(() => {
        getUsers();
    }, [props, filters]);

    useEffect(() => {
        if (selectedUser) {
            console.log('selectedUser', selectedUser);
        }
    }, [selectedUser]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: 'Email',
            key: 'user_email',
            dataIndex: 'user_email',
        },
        {
            title: 'Name',
            key: 'name',
            render: (row) => {
                return (row.first_name || '') + ' ' + ((row.last_name || ''))
            },
        },
        {
            title: 'Profile',
            key: 'profile_id',
            render: (row) => {
                if (row.role) {
                    return row.role;
                }

                return row.profile_id;
            }
        },
        {
            title: 'Verified',
            key: 'is_verified',
            render: (row) => {
                return renderCheck(row.is_verified);
            },
        },
        {
            title: 'Completed',
            key: 'is_completed',
            render: (row) => {
                return renderCheck(row.is_completed);
            },
        },
        {
            title: 'Validated (by admin)',
            key: 'is_validated',
            render: (row) => {
                return renderCheck(row.is_validated);
            },
        },
        {
            title: 'Created at',
            key: 'created_at',
            render: (row) => {
                return row.created_at ? moment(row.created_at).format('YYYY-MM-DD HH:mm') : '-';
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (row) => {
                return <div>
                    <Dropdown overlay={() => { return menu(row); }}>
                        <Button>Actions</Button>
                    </Dropdown>
                </div>;
            },
        },
    ];

    const menu = (row) => {
        return (<Menu>
            <Menu.Item><a onClick={() => { alert('Work in progress') }}>Edit profile</a></Menu.Item>
            {!row.is_validated ? <Menu.Item>
                <a onClick={() => { setSelectedUser(row); setIsValidateModalOpen(true); }}>Validate account</a>
            </Menu.Item> : null}
        </Menu>)
    };


    const renderCheck = (val) => {
        return val ? <Text type="success"><div className="icon icon-check"></div></Text> : <Text type="danger"><div className="icon icon-close"></div></Text>
    }

    const filterTable = (e) => {
        setFilters({ ...e, ...{ page: 1 } });
    }

    const openNotification = (message, description, type = 'info') => {
        apiNotification[type]({
            message,
            description,
            placement: 'bottom'
        });
    }

    const validateAccount = (e) => {
        const formData = formValidateAccount.getFieldsValue();
        privateUserApiService.validateAccount(formData).then(result => {
            formValidateAccount.resetFields();
            setSelectedUser(null);
            setIsValidateModalOpen(false);
            getUsers();
            openNotification('Success', 'Account validated with success');
        }).catch((error) => {
            if (error.response) {
                if (error?.response?.data?.error) {
                    openNotification('Error', error?.response?.data?.error, 'error');
                }
            }
        });
    }

    return (<>
        {contextHolder}
        <Card>
            <div>
                <Form
                    name="filter"
                    onFinish={filterTable}
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                    <Row>
                        <Col lg={4} md={8} sm={12} xs={24} >
                            <Form.Item
                                name="q"
                            >
                                <Input placeholder="key word" />
                            </Form.Item>
                        </Col>
                        <Col lg={4} md={8} sm={12} xs={24} >
                            <Form.Item
                                name="is_verified"
                            >
                                <Select defaultValue={""}>
                                    <option value="" >- Is Verified ? -</option>
                                    <option value="1">Yes</option>
                                    <option value="0" >No</option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={4} md={8} sm={12} xs={24} >
                            <Form.Item
                                name="is_completed"
                            >
                                <Select defaultValue={""}>
                                    <option value="" >- Is Completed ? -</option>
                                    <option value="1">Yes</option>
                                    <option value="0" >No</option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={4} md={8} sm={12} xs={24} >
                            <Form.Item
                                name="is_validated"
                            >
                                <Select defaultValue={""}>
                                    <option value="" >- Is Validated (by admin) ? -</option>
                                    <option value="1">Yes</option>
                                    <option value="0" >No</option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={4} md={8} sm={12} xs={24} >
                            <Form.Item
                                name="role"
                            >
                                <Select defaultValue={""}>
                                    <option value="" >- Role ? -</option>
                                    <option value="ADMIN">Admin</option>
                                    <option value="TALENT">TALENT</option>
                                    <option value="AGENCY">AGENCY</option>
                                    <option value="ADVERTISER">ADVERTISER</option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={4} md={8} sm={12} xs={24} >
                            <Form.Item
                                name="order_by"
                            >
                                <Select defaultValue={"user_id"}>
                                    <option value="user_id" >- Order by (ID) -</option>
                                    <option value="created_at_asc">Created At (Asc)</option>
                                    <option value="created_at_desc">Created At (Desc)</option>
                                    <option value="user_email_asc">Email (Asc)</option>
                                    <option value="user_email_desc">Email At (Desc)</option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={4} md={8} sm={24} xs={24} >
                            <Form.Item className={"text-right"}>
                                <Button type="primary" htmlType="submit">Filter</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Table className="gx-table-responsive" columns={columns} dataSource={users} pagination={{
                defaultCurrent: filters.page,
                current: filters.page,
                total,
                showSizeChanger: false,
                onChange: (val) => {
                    setFilters({ ...filters, ...{ page: val } })
                }
            }} />
        </Card>
        <Modal title="Validate Account" visible={isValidateModalOpen} onOk={validateAccount} onCancel={() => { setIsValidateModalOpen(false); }}>
            {(selectedUser) ?
                <>
                    <Form
                        form={formValidateAccount}
                        name="validate_account"
                        onFinish={validateAccount}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                        <p><label className="gx-font-weight-semi-bold" >ID</label>: <span>{selectedUser?.user_id}</span></p>
                        <p><label className="gx-font-weight-semi-bold" >Email</label>: <span>{selectedUser?.user_email}</span></p>
                        <p><label className="gx-font-weight-semi-bold" >Full name</label>: <span>{selectedUser?.fullname}</span></p>
                        <p><label className="gx-font-weight-semi-bold" >Profile</label>: <span>{selectedUser?.role || selectedUser?.profile_id}</span></p>
                        {selectedUser.role === 'TALENT' && selectedUser.is_validated === false ?
                            <>
                                <p><label className="gx-font-weight-semi-bold" >IG username</label>: <span>{selectedUser?.pre_register?.ig_username}</span></p>
                                <p><label className="gx-font-weight-semi-bold" >Tiktok username</label>: <span>{selectedUser?.pre_register?.tiktok_username}</span></p>
                            </>
                            : null}
                        {selectedUser.role === 'TALENT' && selectedUser.is_validated === true ?
                            <>
                                <p><label className="gx-font-weight-semi-bold" >IG username</label>: <span>{selectedUser?.ig_username}</span></p>
                                <p><label className="gx-font-weight-semi-bold" >Tiktok username</label>: <span>{selectedUser?.ig_username}</span></p>
                            </>
                            : null}
                        {selectedUser.role === 'AGENCY' ?
                            <>
                                <Form.Item name="max_search_count" >
                                    <Input min={0} type="number" placeholder="Max search count" />
                                </Form.Item>
                                <Form.Item name="account_validity_from" defaultValue={new Date()} initialValue={moment().format('YYYY-MM-DD')} >
                                    <Input type="date" placeholder="Account Validity From" value={moment().format('YYYY-MM-DD')} />
                                </Form.Item>
                                <Form.Item name="account_validity_to" >
                                    <Input type="date" placeholder="Account Validity To" />
                                </Form.Item>
                            </>
                            : null}

                        <Form.Item name="user_id" value={selectedUser?.user_id} defaultValue={selectedUser?.user_id} initialValue={selectedUser?.user_id} >
                            <Input type="hidden" value={selectedUser?.user_id} />
                        </Form.Item>
                    </Form>
                </>
                : null}
        </Modal>
    </>);
}

export default AdminManageUsers;
