import React, {useEffect, useCallback} from "react";
import {Avatar, Input, message, Popconfirm} from "antd";
import siteConfig from "../../../config/site.config";
import Pagination from "@material-ui/lab/Pagination";
import Widget from "../../../components/Widget";
import talentApiService from "../../../util/services/TalentsApiService";
import AddTalent from "../all-talents/add-talent/add-talent";
import UpdateTalentProfile from "../all-talents/add-talent/update-talent/update-profile";
import Loader from "../../compain-manager/loader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import {makeStyles} from "@material-ui/core/styles";
import {abbrNum, pourcentNum} from "../../../util/helpers";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from "@material-ui/icons/Delete";
import {translateMsg} from "../../../util/IntlMessages";
import {useIntl} from "react-intl";

const Search = Input.Search;

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    table: {
        minWidth: 650
    },
    selectTableCell: {
        width: 60
    },
    tableCell: {
        width: 130,
        height: 40
    },
    input: {
        width: 130,
        height: 40
    }
}));

const CustomTableCell = ({row, name, onChange, editable}) => {
    const classes = useStyles();
    const {isEditMode} = row;
    return (
        <TableCell align="left" className={classes.tableCell}>
            {isEditMode ? (
                <Input
                    value={row[name]}
                    name={name}
                    onChange={e => onChange(e, row)}
                    disabled={editable}
                    className={classes.input}
                />
            ) : (
                row[name]
            )}
        </TableCell>
    );
};
const TalentsScrapping = (props) => {
    const [allTalents, setAllTalents] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pages, setPages] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [showDrawerAdd, setShowDrawerAdd] = React.useState(false);
    const [showDrawerUpdate, setShowDrawerUpdate] = React.useState(false);
    const [talent_id, setTalent_id] = React.useState(false);
    const [meta_key, setMeta_key] = React.useState('')
    const [previous, setPrevious] = React.useState({});
    const classes = useStyles();
    const intl = useIntl();

    const onToggleEditMode = (id, start_update) => {
        setAllTalents(state => {
            return allTalents.map(row => {
                if (row.influencer_id === id) {
                    if (row.isEditMode && start_update) {
                        row.age = row.age_intervale;
                        talentApiService.updateTalentInfo(row).then(res => {
                            message.success('Account updated with success', 3)
                        }).catch(err => {
                            const {data} = err.response;
                            if (data && data.messages) {
                                data.messages.forEach(msg => {
                                    message.error(translateMsg(msg, intl));
                                });
                            }
                        });
                    }
                    return {...row, isEditMode: !row.isEditMode};
                }
                return row;
            });
        });
    };
    const onChange = (e, row) => {
        if (!previous[row.influencer_id]) {
            setPrevious(state => ({...state, [row.influencer_id]: row}));
        }
        const value = e.target.value;
        const name = e.target.name;
        const {influencer_id} = row;
        const newRows = allTalents.map(row => {
            if (row.influencer_id === influencer_id) {
                return {...row, [name]: value};
            }
            return row;
        });
        setAllTalents(newRows);
    };

    const onRevert = id => {
        const newRows = allTalents.map(row => {
            if (row.influencer_id === id) {
                return previous[id] ? previous[id] : row;
            }
            return row;
        });
        setAllTalents(newRows);
        setPrevious(state => {
            delete state[id];
            return state;
        });
        onToggleEditMode(id, false);
    };

    useEffect(() => {
        getAllTalents(page)
    }, [true]);

    const getAllTalents = (page) => {
        let filter = [];
        filter.push({
            operator: 'and',
            conditions: [
                {
                    field: 'is_valid',
                    operator: 'eq',
                    value: 'N'
                }
            ]
        });
            talentApiService
            .find({
                limit: 20,
                page: page,
                sortBy: 'influencer_id',
                sortDir: 'ASC',
                filter: filter
            })
            .then(res => {
                let talents = res.data.data;
                if (talents && talents.length !==0) {
                    talents.forEach((item, i) => {
                        item.isEditMode = false;
                        talentApiService
                            .getTalentByCode(item.username)
                            .then(res => {
                                item.ig_data = res.data.data?.ig_data;
                                item.followers_count = abbrNum(item.ig_data?.followers_count, 2)
                                item.follows_count = abbrNum(item.ig_data?.follows_count, 2);
                                item.engagement_rate = item.ig_data?.engagement_rate ? pourcentNum(item.ig_data?.engagement_rate) + '%' : '';
                                item.average_likes = abbrNum(item.ig_data?.average_likes, 2);
                                item.average_comments = abbrNum(item.ig_data?.average_comments, 2);
                                if (i === talents.length - 1) {
                                    setIsLoading(false);
                                    setAllTalents(talents || null);
                                }
                            });
                    })
                    setPages(res.data.attributes.pages);
                    setOffset(res.data.attributes.offset);
                    setCount(res.data.attributes.count)
                } else {
                    setIsLoading(false);
                    setAllTalents(talents || null);
                }
            });
    };

    const onFilterTalentsByCode = (code) => {
        setMeta_key(code);
        if (code && code !== '' && code.toString().length > 3 && code !== 'null') {
            let filter = [];
            filter.push({
                operator: 'and',
                conditions: [
                    {
                        field: 'is_valid',
                        operator: 'eq',
                        value: 'N'
                    }
                ]
            });
            talentApiService
                .find({
                    limit: -1,
                    meta_key: code.toString(),
                    sortBy: 'created_at',
                    sortDir: 'DESC',
                    filter: filter,
                })
                .then(res => {
                    let talents_filtred = res.data.data;
                    if (talents_filtred && talents_filtred.length !== 0) {
                        talents_filtred.forEach((item,i) => {
                            item.isEditMode = false
                            talentApiService
                                .getTalentByCode(item.username)
                                .then(res => {
                                    item.ig_data = res.data.data.ig_data;
                                    item.followers_count = abbrNum(item.ig_data.followers_count, 2)
                                    item.follows_count = abbrNum(item.ig_data.follows_count, 2);
                                    item.engagement_rate = pourcentNum(item.ig_data.engagement_rate);
                                    item.average_likes = abbrNum(item.ig_data.average_likes, 2);
                                    item.average_comments = abbrNum(item.ig_data.average_comments, 2);
                                    if (i === talents_filtred.length - 1) {
                                        setIsLoading(false);
                                        setAllTalents(talents_filtred || null);
                                    }
                                });
                        })
                        setPages(res.data.attributes.pages);
                        setOffset(res.data.attributes.offset);
                        setCount(res.data.attributes.count)
                    } else {
                        setIsLoading(false);
                        setAllTalents([] || null);
                        setPages(res.data.attributes.pages);
                        setOffset(res.data.attributes.offset);
                        setCount(res.data.attributes.count)
                    }
                });
        } else {
            setIsLoading(true);
            getAllTalents(1)
        }
    };

    const onChangePage = (event, newPage) => {
        console.log(newPage);
        let current_page = newPage;
        setPage(newPage);
        getAllTalents(current_page);
    };

    function confirm(talent_id) {
        talentApiService.delete({id: talent_id}).then(res => {
            message.success('Talent Deleted With success');
            setIsLoading(true);
            getAllTalents(page)
        })
    }

    function cancel(e) {
    }

    const EnableDrawerAddProfile = () => {
        setShowDrawerAdd(true)
    }
    const disableDrawerAdd = (event) => {
        setShowDrawerAdd(event);
        getAllTalents(1)
    };

    const disableDrawerUpdate = useCallback ((event, meta_key, current_page) => {
        setShowDrawerUpdate(event);
        if (meta_key && meta_key !== '' && meta_key.toString().length > 3 && meta_key !== 'null') {
            onFilterTalentsByCode(meta_key);
        } else {
            getAllTalents(current_page);
        }
    },[]);

    const EnableDrawerUpdateTalent = (talent_id) => {
        setTalent_id(talent_id)
        setShowDrawerUpdate(true)
    }

    return (
        <div>
            {!isLoading ?
                <>
                    <div className={"gx-mb-3"} style={{textAlign: "right"}}>
                        <button className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-text-uppercase "
                                onClick={EnableDrawerAddProfile}>
                            Add
                        </button>
                    </div>
                    <Search
                        enterButton="Search" size="large"
                        placeholder="input talent name"
                        onSearch={value => onFilterTalentsByCode(value)}
                        style={{width: 500}}
                    />
                    {showDrawerAdd ?
                        <AddTalent enable_add={showDrawerAdd} disableDrawerAdd={disableDrawerAdd} is_valid={'N'}/> : ''
                    }
                    {showDrawerUpdate ?
                        <UpdateTalentProfile enable_update={showDrawerUpdate} disableDrawerUpdate={disableDrawerUpdate} meta_key={meta_key}
                                             talent_id={talent_id} current_page={page}/> : ''
                    }
                    <Widget>
                        <Table className={classes.table} aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"/>
                                    <TableCell align="left">Profile image</TableCell>
                                    <TableCell align="left">first name</TableCell>
                                    <TableCell align="left">last name</TableCell>
                                    <TableCell align="left">contact tel</TableCell>
                                    <TableCell align="left">ig_username</TableCell>
                                    <TableCell align="left">Followers count</TableCell>
                                    <TableCell align="left">Follows count</TableCell>
                                    <TableCell align="left">Engagement rate</TableCell>
                                    <TableCell align="left">Avg likes/post</TableCell>
                                    <TableCell align="left">Avg comments/post</TableCell>
                                    <TableCell align="left">More actions</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allTalents.map(row => (
                                    <TableRow key={row.influencer_id}>
                                        <TableCell className={classes.selectTableCell}>
                                            {row.isEditMode ? (
                                                <>
                                                    <IconButton
                                                        aria-label="done"
                                                        onClick={() => onToggleEditMode(row.influencer_id, true)}
                                                    >
                                                        <DoneIcon/>
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="revert"
                                                        onClick={() => onRevert(row.influencer_id)}
                                                    >
                                                        <RevertIcon/>
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => onToggleEditMode(row.influencer_id, false)}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.selectTableCell}>
                                            <Avatar className="gx-size-80"
                                                    src={siteConfig.AppConfig.baseStyleImageUrl + row.profile_efile_id}/>
                                        </TableCell>

                                        <CustomTableCell {...{row, name: "first_name", onChange}} />
                                        <CustomTableCell {...{row, name: "last_name", onChange}} />
                                        <CustomTableCell {...{row, name: "contact_tel", onChange}} />
                                        <TableCell className={classes.selectTableCell}>
                                        <a target={"_blank"} href={"https://instagram.com/" + row?.ig_username}>{row?.ig_username}</a>
                                        </TableCell>
                                        <CustomTableCell {...{
                                            row,
                                            name: "followers_count",
                                            onChange,
                                            editable: true
                                        }} />
                                        <CustomTableCell {...{row, name: "follows_count", onChange, editable: true}} />
                                        <CustomTableCell {...{
                                            row,
                                            name: "engagement_rate",
                                            onChange,
                                            editable: true
                                        }} />
                                        <CustomTableCell {...{row, name: "average_likes", onChange, editable: true}} />
                                        <CustomTableCell {...{
                                            row,
                                            name: "average_comments",
                                            onChange,
                                            editable: true
                                        }} />
                                        <TableCell className={classes.selectTableCell}>
                                            <Fab color="primary" aria-label="edit" size="small"
                                                 onClick={() => EnableDrawerUpdateTalent(row.influencer_id)}>
                                                <EditIcon fontSize="small"/>
                                            </Fab>
                                            <Popconfirm title="Are you sure delete this talent?"
                                                        onConfirm={() => confirm(row.influencer_id)}
                                                        onCancel={cancel} okText="Yes"
                                                        cancelText="No">
                                                <IconButton aria-label="delete" color="secondary">
                                                    <DeleteIcon fontSize="small"/>
                                                </IconButton>
                                            </Popconfirm>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Widget>
                    <Pagination style={{float: "right"}} count={pages} defaultPage={1} page={page} color="primary" size="large" siblingCount={5} onChange={onChangePage}/>
                </> :
                <Loader/>
            }

        </div>
    );
};

export default TalentsScrapping;
