import React,{useState} from "react";
import Widget from "../../../components/Widget/index";
import CircularProgress from "../../../components/CircularProgress";
import CollaborationPublicItems from "./CollaborationPublicItems";

const CollaborationPublic = ({publicCollaborations, pages}) =>{
  const[loader,setLoader]=useState(false);

    return (
      <Widget>
        <div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
          <h2 className="h4 gx-mb-3 gx-mb-sm-1 gx-mr-2"> Les collaborations publiques</h2>
        </div>

        {loader ? <CircularProgress className="gx-loader-400"/> : publicCollaborations?.map((data, index) =>
            <CollaborationPublicItems key={index} data={data} />
          )}

      </Widget>
    );
}

export default CollaborationPublic;
