import React, {useEffect} from "react";
import {Table} from "antd";
import Widget from "../../../../../components/Widget";
import {message, Popconfirm} from "antd";
import talentApiService from "../../../../../util/services/TalentsApiService";
import {useHistory}  from 'react-router-dom';
import siteConfig from "../../../../../config/site.config";

const PublicCollaborationsInfo = ({collaboration, publicStatus}) =>{
  const [collaborationInfo, setCollaborationInfo] = React.useState( []);
  useEffect(() => {
   let infos = []
    infos.push(collaboration)
    setCollaborationInfo(infos)
  }, [collaboration]);
  const columns = [
    {
      title: 'Marques ',
      dataIndex: 'marque',
      render: (text, record) => {
        return <div className="gx-flex-row gx-align-items-center">
          {/*<img className="gx-rounded-circle gx-size-30 gx-mr-2" src={siteConfig.AppConfig.baseStyleImageUrl + text} alt=""/>*/}
          <p className="gx-mb-0">{record.marque}</p>
        </div>
      },
    },
    {
      title: 'Campagne',
      dataIndex: 'compain_name',
      render: (text, record) => {
        return <span className="gx-text-grey">{record.compain_name}</span>
      },

    },
    {
      title: 'Date de Début',
      dataIndex: 'start_date_at',
      render: (text,record) => {
        return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{record.start_date_at}</span>
      },
    },
    {
      title: 'Date de fin ',
      dataIndex: 'end_date_at',
      render: (text, record) => {
        return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{record.end_date_at}</span>
      },
    },
    // {
    //   title: 'Offre',
    //   dataIndex: 'offre',
    //   render: (text, record) => {
    //     return <span className="gx-text-primary gx-pointer">
    //       <button className="ant-btn ant-btn-success">{record.offre}</button></span>
    //   },
    // },

  ];
  const history = useHistory();

 const sendRequest = () => {
     let talent_id = localStorage.getItem('talent_id')
   let formData = {
      compain_id: collaboration.compain_id,
      talent_id: talent_id
   }
   talentApiService
     .sendRequestPublicCompain(formData).then(res => {
       if (res.data.success === true) {
         message.success('Request Sended With success');
         history.push(siteConfig.prefixMe + '/mes-collaborations')

       }
   })
 }

  function cancel(e) {
  }

  return (
    <div>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0">
            Collaboration Details</h2>
        }>
        <div className="gx-table-responsive">
          <Table className="gx-table-no-bordered" columns={columns} dataSource={collaborationInfo} pagination={false}
                 size="small"/>
        </div>
      </Widget>
      <Widget>
        <div className="gx-table-responsive">
            <Widget styleName="gx-card-profile">
                <div className="ant-card-head">
                    <span className="ant-card-head-title gx-mb-2">Offre</span>
                </div>
                <p>{collaboration?.offre}</p>
            </Widget>
          <Widget styleName="gx-card-profile">
            <div className="ant-card-head">
              <span className="ant-card-head-title gx-mb-2">Brief Client</span>
            </div>
            <p>{collaboration?.description}</p>
          </Widget>
        </div>
      </Widget>
        <div className={"text-center gx-mb-3"}>
          <Popconfirm title="Are you sure you want to participate to this Campaign?" onConfirm={sendRequest} onCancel={cancel} okText="Yes"
                      cancelText="No">
            <span className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-mr-4 gx-text-uppercas">Postuler</span>
          </Popconfirm>
        </div>
    </div>
  );
};

export default PublicCollaborationsInfo;
