import React, {useEffect} from "react";
import {Avatar, Col, Input, message, Popconfirm, Popover, Row} from "antd";
import siteConfig from "../../../config/site.config";
import {useIntl} from "react-intl";
import Pagination from "@material-ui/lab/Pagination";
import Widget from "../../../components/Widget";
import talentApiService from "../../../util/services/TalentsApiService";
import AddTalent from "./add-talent/add-talent";
import UpdateTalentProfile from "./add-talent/update-talent/update-profile";
import Loader from "../../compain-manager/loader";
import Switch from "@material-ui/core/Switch";
import advertiserApiService from "../../../util/services/AdvertisersApiService";
import {translateMsg} from "../../../util/IntlMessages";
import BaseApiService from "../../../util/BaseApiService";
const baseApiService = new BaseApiService();

const Search = Input.Search;

const TalentsManager = (props) => {
    const [allTalents, setAllTalents] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pages, setPages] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [showDrawerAdd, setShowDrawerAdd] = React.useState(false);
    const [showDrawerUpdate, setShowDrawerUpdate] = React.useState(false);
    const [talent_id, setTalent_id] = React.useState(false);
    const [meta_key, setMeta_key] = React.useState('')

    const intl = useIntl();

    useEffect(() => {
        getAllTalents(page)
    }, [true]);

    const getAllTalents = (page) => {
        let filter = [];
        filter.push({
            operator: 'and',
            conditions: [
                {
                    field: 'is_valid',
                    operator: 'eq',
                    value: 'Y'
                }
            ]
        });
        talentApiService
            .find({
                limit: 8,
                page: page,
                filter: filter,
                sortBy: 'created_at',
                sortDir: 'DESC',
            })
            .then(res => {
                setIsLoading(false);
                setAllTalents(res.data.data || null);
                setPages(res.data.attributes.pages);
                setOffset(res.data.attributes.offset);
                setCount(res.data.attributes.count)
            });
    };

    const onFilterTalentsByCode = (code) => {
        setMeta_key(code)
        if (code && code !== '' && code.toString().length > 3) {
            talentApiService
                .find({
                    limit: -1,
                    meta_key: code.toString(),
                    sortBy: 'created_at',
                    sortDir: 'DESC',
                })
                .then(res => {
                    setIsLoading(false);
                    setAllTalents(res.data.data || null);
                    setPages(res.data.attributes.pages);
                    setOffset(res.data.attributes.offset);
                    setCount(res.data.attributes.count)
                });
        } else {
            getAllTalents(page)
        }
    };

    const onChangePage = (event, newPage) => {
        let current_page = newPage
        setPage(current_page)
        getAllTalents(current_page)
    };

    const userMenuOptions = (talent_id) => (
        <ul className="gx-user-popover">
            <li onClick={() => EnableDrawerUpdateTalent(talent_id)}>Update profile</li>
            <Popconfirm title="Are you sure delete this talent?" onConfirm={() => confirm(talent_id)}
                        onCancel={cancel} okText="Yes"
                        cancelText="No">
                <li>Delete</li>
            </Popconfirm>
        </ul>
    );

    function confirm(talent_id) {
        talentApiService.delete({id: talent_id}).then(res => {
            baseApiService.deleteUserByADid({talent_id: talent_id, profile :'talent'}).then(res => {
                message.success('Talent Deleted With success');
                getAllTalents(page);
            }).catch(err => {
                const {data} = err.response;
                if (data && data.messages) {
                    data.messages.forEach(msg => {
                        message.error(translateMsg(msg, intl));
                    });
                }
            });
        })
    }

    function cancel(e) {
    }

    const EnableDrawerAddProfile = () => {
        setShowDrawerAdd(true)
    }
    const disableDrawerAdd = (event) => {
        setShowDrawerAdd(event);
        getAllTalents(page)
    };

    const disableDrawerUpdate = (event) => {
        setShowDrawerUpdate(event);
        getAllTalents(page)
    };

    const EnableDrawerUpdateTalent = (talent_id) => {
        setTalent_id(talent_id)
        setShowDrawerUpdate(true)
    };

    const handleChangeStatusTalent = (data) => {
        const formAgency = {is_verified: data.is_verified === 'Y' ? 'N' : 'Y'};
        formAgency.influencer_id = data.influencer_id;
        console.log(formAgency);
        talentApiService.update(formAgency).then(res => {
            getAllTalents(page);
            if (res.data.data.is_verified === 'N') {
                message.success('Talent disabled with success', 3);
            } else {
                message.success('Talent validated with success', 3);
            }
            disableDrawerUpdate(false)

        }).catch(err => {
            const {data} = err.response;
            if (data && data.messages) {
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    }

    return (
        <div>
            {!isLoading ?
                <>
                    <div className={"gx-mb-3"} style={{textAlign: "right"}}>
                        <button className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-text-uppercase "
                                onClick={EnableDrawerAddProfile}>
                            Add
                        </button>
                    </div>
                    <Search
                        enterButton="Search" size="large"
                        placeholder="input talent name"
                        onSearch={value => onFilterTalentsByCode(value)}
                        style={{width: 500}}
                    />
                    <Row>
                        {allTalents.map((item_talent, idx) => (
                            <Col key={idx} xl={6} md={8} sm={12} xs={24}>
                                <Widget styleName="gx-card-full" extra={
                                    <Popover content={userMenuOptions(item_talent.influencer_id)}>
                                        <i className="icon icon-setting gx-text-grey gx-fs-xl"/>
                                    </Popover>}
                                        title={
                                            <>
                                                <div className="gx-py-3">
                                                    <Row>
                                                        <Popconfirm
                                                            title={item_talent.is_verified === 'N' ? "Are you sure you want to validate this talent?" : "Are you sure you want to disabled this talent?"}
                                                            onConfirm={() => handleChangeStatusTalent(item_talent)}
                                                            onCancel={cancel} okText="Yes"
                                                            cancelText="No">
                                                            <Switch
                                                                checked={item_talent.is_verified === 'Y'}
                                                                color="primary"
                                                                name="checkedB"
                                                                inputProps={{'aria-label': 'primary checkbox'}}
                                                            />
                                                        </Popconfirm>
                                                    </Row>
                                                </div>
                                            </>

                                        }>
                                    <div className="gx-text-center gx-px-3 gx-pt-3">
                                        <div
                                            className="gx-d-flex gx-justify-content-around gx-align-items-center gx-mb-3">
                                            <Avatar className="gx-size-80"
                                                    src={siteConfig.AppConfig.baseStyleImageUrl + item_talent.profile_efile_id}/>
                                        </div>
                                        <div className="gx-mb-3">
                                            <h2>{item_talent?.influencer_name}</h2>
                                            <p className="gx-text-grey"><a target={"_blank"}
                                                                           href={siteConfig.SignUpTalentUrl + '?code=' + item_talent?.code}>{item_talent?.code}</a>
                                            </p>
                                            <p className="gx-text-grey"><a target={"_blank"}
                                                                           href={"https://instagram.com/" + item_talent?.ig_username}>@{item_talent?.ig_username}</a>
                                            </p>
                                        </div>
                                    </div>
                                </Widget>
                            </Col>

                        ))}
                    </Row>
                    {showDrawerAdd ?
                        <AddTalent enable_add={showDrawerAdd} disableDrawerAdd={disableDrawerAdd} is_valid={'Y'}/> : ''
                    }
                    {showDrawerUpdate ?
                        <UpdateTalentProfile enable_update={showDrawerUpdate} disableDrawerUpdate={disableDrawerUpdate}
                                             talent_id={talent_id}/> : ''
                    }
                    <Pagination style={{float: "right"}} count={pages} color="primary" onChange={onChangePage}/>
                </> :
                <Loader/>
            }

        </div>
    );
};

export default TalentsManager;


