import React, {useEffect, useState} from "react";
import siteConfig from "../../../config/site.config";
import {useIntl} from "react-intl";
import {translateMsg} from "../../../util/IntlMessages";
import {Avatar, message, Spin} from "antd";
import FBValidatorConnector from "../../FBComponents/FBValidatorAccount";
import fbApiService from "../../../util/services/FBApiService";
import HeaderProfileIGData from "../../profile/HeaderProfileIGData/HeaderProfileIGData";
import {CopyToClipboard} from "react-copy-to-clipboard";

const ProfileHeader = ({talent_info, mode}) => {
  const [talent, setTalent] = useState(talent_info);
  const [commentValidationLoading, setCommentValidationLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setTalent(talent_info);
  }, [talent_info]);

  const callbackFBConnector = (data) => {
    setTalent({...talent, ...{is_valid: 'Y'}});
    message.success(translateMsg("AccountValidatedWithSuccess", intl), 3, () => {
      window.location.reload();
    });
  }

  const sendCommentValidation = () => {
    setCommentValidationLoading(true);
    fbApiService.validateIGWithComment().then(r => {
      setCommentValidationLoading(false);
      setTalent({...talent, ...{is_valid: 'Y'}});
      message.success(translateMsg("AccountValidatedWithSuccess", intl), 3, () => {
        window.location.reload();
      });
    }).catch(e => {
      setCommentValidationLoading(false);

      if (e && e.response && e.response.data) {
        const {messages} = e.response.data;
        messages.forEach(msg => {
          message.error(translateMsg(msg, intl));
        });
      }
    });
  };

  const ShowAlertCopy = () => {
    message.success('Code Copied  With success');
  }

  return (
      <div className="gx-profile-banner">
        <div className="gx-profile-container">
          <div className="gx-profile-banner-top">
            <div className="gx-profile-banner-top-left">
              <div className="gx-profile-banner-avatar">
                <Avatar className="gx-size-90" alt="..."
                        src={siteConfig.AppConfig.baseStyleImageUrl + talent?.profile_efile_id}/>
              </div>
              <div className="gx-profile-banner-avatar-info">
                <h2 className="gx-mb-2 gx-fs-xxl gx-font-weight-light">
                  <span>{talent?.influencer_name}</span>
                  {talent.is_valid === "Y" ? <img alt={"Verified account"} className={"gx-ml-3"}
                                                  src={require("../../../assets/images/check.svg")} width={28}/> : <span className={"unverified-span"}>(non vérifié)</span>}
                </h2>
              </div>
            </div>
            {talent && talent.is_valid === 'Y' ?
                <div className="gx-profile-banner-top-right">
                  <HeaderProfileIGData ig_data={talent.ig_data} />
                </div>:''
            }
          </div>
        </div>
        {talent.is_valid !== "Y" && mode === "owner" ?
            <div className={"gx-mb-3"} style={{textAlign: "center", width: '47em', marginLeft: "auto", marginRight: "auto"}}>
            <p>
               Bienvenue dans votre espace TAWA Talents. Des opportunités de collaborations
              avec des Marques vous seront désormais proposées. Pour cela, suivez cette
              dernière étape pour valider votre compte et confirmer que vous êtes son
              propriétaire:
            </p>
            {/*<p>Connectez-vous en utilisant Instagram pour vérifier votre compte</p>*/}
            {/*<FBValidatorConnector profileType={"advertiser"} callbackFBConnector={(data) => {*/}
            {/*  callbackFBConnector(data);*/}
            {/*}}/>*/}
          <div>
            {/*<p>- Ou -</p>*/}
            <div>
              <ul>
                <li>
                  Copier ce code "Hello {talent.user_id}"
                  <CopyToClipboard text={"Hello"+ talent.user_id}
                                   onCopy={() => ShowAlertCopy()}>
                    <button className="btn-check-comment" style={{float: "right"}}>Copy</button>
                  </CopyToClipboard>
                  </li>
                <li>
                  Rendez-vous sur ce compte instagram  <a rel={"noopener noreferrer"} target={"_blank"} href={'https://instagram.com/p/' + siteConfig.facebook.validation_post_code}>
                  cette publication</a>
                  </li>
                <li>
                  Coller le code en commentaire sous la publication. Vous pouvez supprimer le
                  commentaire après la validation

                </li>
                <li>
                  puis cliquez <button disabled={commentValidationLoading} onClick={() => sendCommentValidation()} className={"btn-check-comment"} type={"button"}>ici</button>
                </li>
              </ul>
              {/*{commentValidationLoading ? */}
              {/*<Spin className={"gx-m-0 gx-ml-2 ant-spin-xs"} /> */}
              {/*    : '' }*/}
            </div>
          </div>
        </div> : ''}
      </div>
  )
};

export default ProfileHeader;
