import React, {useEffect} from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import {Button, Card, Form, Input, Modal, Upload} from "antd";
import {makeStyles} from "@material-ui/core/styles";
import BaseApiService from "../../../../util/BaseApiService";
import IntlMessages, {translateMsg} from "../../../../util/IntlMessages";
import {message} from "antd/lib/index";
import {useIntl} from "react-intl";
import {post} from "axios";
import siteConfig from "../../../../config/site.config";
import {UploadOutlined} from "@ant-design/icons";

const baseApiService = new BaseApiService();


const UpdateManager = ({enable_update, disableDrawerUpdate, manager_id}) => {
    const [manager, setManager] = React.useState({});
    const [form] = Form.useForm();
    const intl = useIntl();
    const [stateDrawer, setStateDrawer] = React.useState(enable_update);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewPicture, setPreviewPicture] = React.useState('');
    const [manager_efile_id, setManager_efile_id] = React.useState();
    const useStyles = makeStyles({
        list: {
            width: 1000,
            maxWidth: '100%',
        },
        fullList: {
            width: 'auto',
        },
    });
    const classes = useStyles();
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    const getManagerInfo = () => {
        baseApiService.findUserById(manager_id)
            .then(res => {
                setManager(res.data.data || null);
                setManager_efile_id(res.data.data.profile_efile_id)
            });
    };

    useEffect(() => {
        getManagerInfo()
    }, []);

    useEffect(() => {
        let formData = {...manager, ...{}};
        form.setFieldsValue(formData);
        form.setFieldsValue({
            first_name: formData.first_name,
            last_name: formData.last_name,
            contact_tel: formData?.phone,
        });

    }, [manager]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        disableDrawerUpdate(false);
        setStateDrawer(open);
    };

    const onFinish = values => {
        const formProfile = values;
        formProfile.user_id = manager_id;
        formProfile.profile_image_id = manager_efile_id;
        formProfile.contact_tel = formProfile.contact_tel;
        baseApiService.updateUser(formProfile).then(res => {
            message.success('Account updated with success', 3);
            disableDrawerUpdate(false)
        }).catch(err => {
            const {data} = err.response;
            if (data && data.messages) {
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    };

    const FormProfile = (anchor) => {
        return (<div
                className={clsx(classes.list, {
                    [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                })}
                role="presentation"
            >
                <Card className="gx-card" title="Update Profile">
                    <Form
                        {...formItemLayout}
                        form={form}
                        name="update"
                        onFinish={onFinish}
                        initialValues={{
                            prefix: '216',
                        }}
                        scrollToFirstError
                        autoComplete={"off"}
                    >
                        <Form.Item
                            name="username"
                            label="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your user name!',
                                },
                            ]}
                        >
                            <Input disabled={true}/>
                        </Form.Item>
                        <Form.Item
                            name="first_name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your First name!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            label="Family Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Last name!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="contact_tel"
                            label="Phone Number"
                            rules={[{required: true, message: 'Please input your phone number!'}]}
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <Form.Item
                            name="user_email"
                            label="Email"
                        >
                            <Input style={{width: '100%'}} />
                        </Form.Item>

                        <Form.Item
                            name="passwordhash"
                            label="Password"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input your password!',
                                },
                            ]}
                            autoComplete={"new-password"}
                            hasFeedback
                        >
                            <Input.Password autoComplete={"new-password"} />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['passwordhash']}
                            hasFeedback
                            rules={[
                                {
                                    required: false,
                                    message: 'Please confirm your password!',
                                },
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if ((!value && !getFieldValue('passwordhash')) || getFieldValue('passwordhash') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password autoComplete={"new-password"} />
                        </Form.Item>
                        <Form.Item
                            label={"Manager picture"}>
                            <div className={"group-file-upload"}>
                                <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={handleUploadClick}
                                />
                                <Button icon={<UploadOutlined/>} onClick={() => {
                                    document.getElementById('contained-button-file').click()
                                }}>Click to upload</Button>
                            </div>
                        </Form.Item>
                        {manager_efile_id ?
                            <Card title="Manager picture" className="gx-card clearfix">
                                <Upload
                                    listType="picture-card"
                                    fileList={ManagerPicture}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                >
                                </Upload>
                                <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                    <img alt="Manager picture" style={{width: '100%'}} src={previewPicture}/>
                                </Modal>
                            </Card> : ''
                        }

                        <Form.Item {...tailFormItemLayout} >
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                            <Button type="secondary" onClick={() => disableDrawerUpdate(false)}><IntlMessages
                                id="drawer.cancel"/></Button>
                        </Form.Item>
                    </Form>
                </Card>

            </div>
        );
    };

    const handleUploadClick = event => {
        const file = event.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            return post(siteConfig.AppConfig.fileUploadUrl, formData, {
                headers: {
                    'Content-Type': `multipart/form-data;`,
                },
                timeout: 30000,
            }).then(res => {
                setManager_efile_id(res.data.data)
            })
        }
    };
    const handleCancel = () => {
        setPreviewVisible(false)
    }
    const handlePreview = (file) => {
        setPreviewPicture(file.url || file.thumbUrl)
        setPreviewVisible(true)
    };
    const ManagerPicture = [{
        uid: -1,
        name: 'manager picture',
        status: 'done',
        url: siteConfig.AppConfig.baseStyleImageUrl + manager_efile_id,
    }]

    const handleChange = () => {
        setManager_efile_id(null)
    }

    return (
        <Drawer anchor={'right'} open={stateDrawer}
                onClose={toggleDrawer(false)}>
            {FormProfile('right')}
        </Drawer>
    )
};

export default UpdateManager;
