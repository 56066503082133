import React, {useEffect} from "react";
import {Col, Table, Row, Button, Card} from "antd";
import Widget from "../../../../components/Widget";
import siteConfig from "../../../../config/site.config";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import data from "./data";
import {message, Popconfirm} from "antd";
import talentApiService from "../../../../util/services/TalentsApiService";
import {Link, useHistory} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const CollaborationsInfo = ({collaboration, publicStatus, props}) =>{
  const [stats, setStats] = React.useState( []);
  const columns = [
    {
      title: 'Photo ',
      dataIndex: 'profile_efile_id',
      render: (text, record) => {
        return <div className="gx-flex-row gx-align-items-center">
            <img className="gx-rounded-circle gx-size-30 gx-mr-2" src={siteConfig.AppConfig.baseStyleImageUrl + record?.compain_info?.advertiser?.profile_efile_id} alt=""/>
        </div>
      },
    },
    {
      title: 'Marques ',
      dataIndex: 'marque',
      render: (text, record) => {
        return <div className="gx-flex-row gx-align-items-center">
          {/*<img className="gx-rounded-circle gx-size-30 gx-mr-2" src={siteConfig.AppConfig.baseStyleImageUrl + text} alt=""/>*/}
          <p className="gx-mb-0">{record.marque}</p>
        </div>
      },
    },
    {
      title: 'Campagne',
      dataIndex: 'compain_name',
      render: (text, record) => {
        return <span className="gx-text-grey">{record.compain_name}</span>
      },

    },
    {
      title: 'Date de Début',
      dataIndex: 'start_date_at',
      render: (text,record) => {
        return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{record.start_date_at}</span>
      },
    },
    {
      title: 'Date de fin ',
      dataIndex: 'end_date_at',
      render: (text, record) => {
        return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{record.end_date_at}</span>
      },
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      render: (text, record) => {
        return <span className="gx-text-primary gx-pointer">
          <button className="ant-btn ant-btn-success">{record.status && record.status === 'need_validation'? 'En cours de traitement':record.status }</button></span>
      },
    },

  ];
  const history = useHistory();
  const talent_id = localStorage.getItem('talent_id')

  const AcceptInvitation = () => {
    let formData = {
      compain_id: collaboration[0].compain_id,
      talent_id: talent_id
    }
    talentApiService
      .acceptInvitation(formData).then(res => {
      if (res.data.success === true) {
        message.success('Invitation Accepted With success');
        window.location.reload(true);

      }
    })
  }

  const DeclineInvitation = () => {
    let formData = {
      compain_id: collaboration[0].compain_id,
      talent_id: talent_id
    }
    talentApiService
      .declineInvitation(formData).then(res => {
      if (res.data.success === true) {
        message.success('Invitation Declined With success');
        history.push(siteConfig.prefixMe + '/mes-collaborations')

      }
    })
  }

  useEffect(() => {
    talentApiService
      .getStatCompainDetails(collaboration[0]?.compain_influencer_id)
      .then(res => {
        setStats(res.data.data || []);
      });
  }, [collaboration[0]?.compain_influencer_id]);

 const ShowAlertCopy = () => {
   message.success('Link Copied  With success');
 }
  function cancel(e) {
  }
  return (
    <div>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0">
            Collaboration Details</h2>
        }>
        <div className="gx-table-responsive">
          <Table className="gx-table-no-bordered" columns={columns} dataSource={collaboration} pagination={false}
                 size="small"/>
        </div>
      </Widget>
      <Widget>
        <div className="gx-table-responsive">
          {collaboration[0]?.compain_info.category === 'public' ?
              <Widget styleName="gx-card-profile">
                <div className="ant-card-head">
                  <span className="ant-card-head-title gx-mb-2">Offre</span>
                </div>
                <p>{collaboration[0]?.compain_info.offre}</p>
              </Widget>:''
          }
          <Widget styleName="gx-card-profile">
            <div className="ant-card-head">
              <span className="ant-card-head-title gx-mb-2">Brief Client</span>
            </div>
            <p>{collaboration[0]?.compain_info.description}</p>
          </Widget>
          {collaboration[0]?.status !== 'En attente' && !publicStatus && collaboration[0]?.status !== 'need_validation' ?
          <Widget styleName="gx-card-profile">
            <div className="ant-card-head">
            <span className="ant-card-head-title gx-mb-2">Lien Swipe UP</span>
              <CopyToClipboard text={siteConfig.AppConfig.apiBaseRedirectUrl + collaboration[0]?.code}
                               onCopy={() => ShowAlertCopy()}>
                <button className="ant-btn ant-btn-primary" style={{float: "right"}}>Copy</button>
              </CopyToClipboard>
            </div>
            <p><a href={siteConfig.AppConfig.apiBaseRedirectUrl + collaboration[0]?.code} target="_blank">{siteConfig.AppConfig.apiBaseRedirectUrl + collaboration[0]?.code}</a></p>
            </Widget>: ''
          }
        </div>
      </Widget>
      {collaboration[0]?.status !== 'En attente' && !publicStatus && collaboration[0]?.status !== 'need_validation' ?
        <Widget>
          {/*<ResponsiveContainer width="100%" height={200}>*/}
          {/*  <AreaChart data={stats}*/}
          {/*             margin={{top: 10, right: 0, left: -15, bottom: 0}}>*/}
          {/*    <XAxis dataKey="date"/>*/}
          {/*    <YAxis/>*/}
          {/*    <CartesianGrid strokeDasharray="3 3"/>*/}
          {/*    <Tooltip/>*/}
          {/*    <Area type='monotone' dataKey='total_visit' fillOpacity={100} stroke='#003366' fill='#003366'/>*/}
          {/*  </AreaChart>*/}
          {/*</ResponsiveContainer>*/}

          <ResponsiveContainer width="100%" height={180}>
            <AreaChart data={stats}
                       margin={{top: 0, right: 0, left: 0, bottom: 0}}>
              <XAxis dataKey="date"/>
              <YAxis/>
              <Tooltip/>
              <defs>
                <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
                  <stop offset="5%" stopColor="#4ECDE4" stopOpacity={0.9}/>
                  <stop offset="95%" stopColor="#06BB8A" stopOpacity={0.9}/>
                </linearGradient>
              </defs>
              <Area dataKey="total_visit" strokeWidth={0} stackId="2"  stroke='#4D95F3' fill="url(#color4)"
                    fillOpacity={1}/>
            </AreaChart>
          </ResponsiveContainer>
        </Widget> : ''
      }
      {collaboration[0]?.status === 'En attente' && !publicStatus && collaboration[0]?.status !== 'need_validation' ?
          <div className={"text-center gx-mb-3"}>
          <Popconfirm title="Are you sure you want to participate to this Compaign?" onConfirm={AcceptInvitation} onCancel={cancel} okText="Yes"
                      cancelText="No">
            <span className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-mr-4 gx-text-uppercas">Accepter</span>
          </Popconfirm>
          <Popconfirm title="Are you sure you want to decline this Compaign?" onConfirm={DeclineInvitation} onCancel={cancel} okText="Yes"
                      cancelText="No">
            <span className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-mr-4 gx-text-uppercas">Refuser</span>
          </Popconfirm>
        </div> : ''
      }
      {collaboration[0]?.status === 'need_validation' ?
          <div className={"text-center gx-mb-3"}>
            <Button className="ant-btn gx-btn-secondary gx-mt-md-5 gx-mb-1 ant-btn-lg" >
              <span>En attente de validation</span>
            </Button>
          </div> : ''
      }
    </div>
  );
};

export default CollaborationsInfo;
