import React, {useEffect, Fragment} from "react";
import talentApiService from "../../../../../util/services/TalentsApiService";
import CircularProgress from '@material-ui/core/CircularProgress';
import Auxiliary from "../../../../../util/Auxiliary";
import {Col, Row} from "antd";
import PublicCollaborationsInfo from "./publicCollaborationsDetails";
import compainApiService from "../../../../../util/services/CompainApiService";
import Loader from "../../../../compain-manager/loader";

const PublicCollaborationDetails = (props) => {
  const [Collaboration, setCollaboration] = React.useState([]);
  const [compain_id, setCompain_id] = React.useState( props.match.params.compain_id);
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    compainApiService
      .getCompainInfo(compain_id)
      .then(res => {
        setIsLoading(false);
        setCollaboration(res.data.data || null);
      });
  }, [compain_id]);
  return (
    <div>
      <div>
        {!isLoading ?
          <Fragment>
            <Auxiliary>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <PublicCollaborationsInfo collaboration={Collaboration} publicStatus={false}/>
                </Col>
              </Row>

            </Auxiliary>
          </Fragment>
          :  <Loader/>}
      </div>
    </div>
  );
};

export default PublicCollaborationDetails;
