import BaseApiService from "../../BaseApiService";
import { apiUrl } from "../../../config/site.config";

class PrivateUserApiService extends BaseApiService {
    find(options = {}) {
        const {
            q = null,
            limit = 10,
            page = 1,
            is_verified = null,
            is_completed = null,
            is_validated = null,
            role = null,
        } = options;
        const queryParams = [];
        queryParams.push(['limit', limit]);
        queryParams.push(['page', page]);
        if (q) queryParams.push(['q', q]);
        if (is_completed) queryParams.push(['is_completed', is_completed]);
        if (is_verified) queryParams.push(['is_verified', is_verified]);
        if (is_validated) queryParams.push(['is_validated', is_validated]);
        if (role) queryParams.push(['role', role]);
        const params = new URLSearchParams(queryParams);
        return this.setHeaderToken().get(apiUrl + '/v2/private/user/find', { params });
    }

    validateAccount(options = {}) {
        const {
            user_id = null,
            max_search_count = null,
            account_validity_to = null,
            account_validity_from = null,
        } = options;
        return this.setHeaderToken().post(apiUrl + '/v2/private/user/validateAccount', { user_id, max_search_count, account_validity_to, account_validity_from });
    }
}

const privateUserApiService = new PrivateUserApiService();

export default privateUserApiService;