import React from "react";
import Widget from "../../Widget";
import {InstagramOutlined} from "@ant-design/icons";

const Contact = ({talent_info}) => {
  const contactList = [
    {
      id: 4,
      title: 'Instagram',
      icon2: <InstagramOutlined />,
      desc: (talent_info.ig_username) ? [<a key={"ig_link"} rel="noopener noreferrer" target={"_blank"} href={"https://instagram.com/" + talent_info?.ig_username}>@{talent_info.ig_username}</a>] : null
    },
  ];

  return (
    <Widget title="Contact" styleName="gx-card-profile-sm">
      {contactList.map((data, index) =>
        <div key={data.id} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            {data.icon ? <i className={`icon icon-${data.icon} gx-fs-xxl gx-text-grey`}/> : ''}
            {data.icon2 ? <span className={"gx-text-grey"} style={{fontSize: 24}}>{data.icon2}</span> : ''}
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">{data.title}</span>
            <p className="gx-mb-0">{data.desc}</p>
          </div>
        </div>
      )}
    </Widget>
  )
}

export default Contact;
