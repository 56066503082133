import BaseApiService from "../BaseApiService";
import * as axios from "axios";
import {apiUrl} from "../../config/site.config";

class AdvertisersApiService extends BaseApiService {
  constructor() {
    super('annonceur');
  }

  getAdvertiserByCode(data, fulldata = true) {
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getadvertiserByusername?username='+ data + (fulldata ? '&fulldata=1' : '') );
  }

  getMyBusinessProfile() {
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getMyBusinessProfile');
  }

  getPublicComppains(params = {}) {
    const params_encoded = (params && Object.keys(params).length) ? encodeURIComponent(JSON.stringify(params)) : '';
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getComppains/'+ params_encoded);
  }

  getPrivateComppains(params = {}) {
    const params_encoded = (params && Object.keys(params).length) ? encodeURIComponent(JSON.stringify(params)) : '';
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getPrivateCompains/'+ params_encoded);
  }

  getStatCompain(compain_id) {
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getStatCompain?compain_id='+ compain_id);
  };

  importMediasFromIG() {
    return this.setHeaderToken().post(apiUrl + '/' + this.entity_name + '/importMediasFromIG');
  };

  getIGAccountInfo(data) {
    return this.setHeaderToken().post(apiUrl + '/' + this.entity_name + '/getProfileIGInfos', data);
  };

  updateAdvertiser(data) {
    return this.setHeaderToken().put(apiUrl + '/' + this.entity_name + '/updateadvertiser', data);
  };

  updateAdvertiserInfo(data) {
    return this.setHeaderToken().post(apiUrl + '/' + this.entity_name + '/updateAdvertiserInfo', data);
  };

  updateAgencyInfo(data) {
    return this.setHeaderToken().post(apiUrl + '/' + this.entity_name + '/updateAgencyInfo', data);
  };

  getAdByCode(code) {
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getAdByCode?code='+ code );
  };
}

const advertiserApiService = new AdvertisersApiService();

export default advertiserApiService;
