import React, {useEffect} from "react";
import Widget from "../Widget";
import {Avatar, Col, Row} from "antd";
import {abbrNum, pourcentNum} from "../../util/helpers";
import IconWithTextCard from "../Metrics/IconWithTextCard";
import siteConfig from "../../config/site.config";

const IgAccountInfo = (igAccountInfo) => {
    const [igAccountData, setIgAccountData] = React.useState(null);
    const [profile_efile_id, setProfile_efile_id] = React.useState(igAccountInfo?.igAccountInfo?.profile_efile_id);

    useEffect(() => {
        if (igAccountInfo) {
            setIgAccountData(igAccountInfo);
            if (igAccountInfo.igAccountInfo.profile_efile_id) {
                setProfile_efile_id(igAccountInfo?.igAccountInfo?.profile_efile_id);
            }
        }
    }, [igAccountInfo]);
    return (
        <>
            {igAccountData && igAccountData.igAccountInfo  ?
                <Widget styleName="gx-card-full">
                    <div className="gx-text-center gx-px-3 gx-pt-3">
                        <div className="gx-d-flex gx-justify-content-around gx-align-items-center gx-mb-3">
                                <Avatar className="gx-size-80"
                                        src={siteConfig.AppConfig.baseStyleImageUrl + profile_efile_id}/>
                        </div>
                        <div className="gx-mb-3">
                            <h2>{igAccountInfo?.igAccountInfo?.ig_full_name}</h2>
                        </div>
                    </div>
                    <Row className="gx-follower-list">
                        <Col className="gx-col-full">
                            <IconWithTextCard icon="revenue-new" iconColor="geekblue"
                                              title={pourcentNum(igAccountInfo?.igAccountInfo?.engagement_rate)}
                                              subTitle="Engagement Rate"/>
                        </Col>
                        <Col className="gx-col-full gx-ml-2">
                            <IconWithTextCard icon="dasbhoard" iconColor="geekblue"
                                              title={abbrNum(igAccountInfo?.igAccountInfo?.followers_count, 2)}
                                              subTitle="Followers"/>
                        </Col>
                        <Col className="gx-col-full gx-ml-2">
                            <IconWithTextCard style={{marginLeft: '-20px'}} icon="thumb-up" iconColor="geekblue"
                                              title={abbrNum(igAccountInfo?.igAccountInfo?.average_likes, 0)}
                                              subTitle="Avg Likes/Post"/>
                        </Col>
                        <Col className="gx-col-full gx-ml-2">
                            <IconWithTextCard style={{marginLeft: '-20px'}} icon="feedback" iconColor="geekblue"
                                              title={abbrNum(igAccountInfo?.igAccountInfo?.average_comments, 0)}
                                              subTitle="Avg Comments/Post"/>
                        </Col>
                        {/*<Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">*/}
                        {/*    <div className="gx-d-flex gx-flex-row gx-ml-2">*/}
                        {/*        <i className="icon icon-menu-up gx-text-geekblue gx-mr-2 gx-pt-1"/>*/}
                        {/*        <h2 className="gx-text-geekblue">{pourcentNum(igAccountInfo?.igAccountInfo?.engagement_rate)}%</h2>*/}
                        {/*    </div>*/}
                        {/*    <p className="gx-text-left gx-text-grey gx-ml-2">Engagement rate</p>*/}
                        {/*</Col>*/}
                        {/*<Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">*/}
                        {/*    <div className="gx-d-flex gx-flex-row gx-ml-2">*/}
                        {/*        <i className="icon icon-menu-up gx-text-geekblue gx-mr-2 gx-pt-1"/>*/}
                        {/*        <h2 className="gx-text-geekblue">{abbrNum(igAccountInfo?.igAccountInfo?.followers_count,2)}</h2>*/}
                        {/*    </div>*/}
                        {/*    <p className="gx-text-left gx-text-grey gx-ml-2">Followers count</p>*/}
                        {/*</Col>*/}
                        {/*<Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">*/}
                        {/*    <div className="gx-d-flex gx-flex-row gx-ml-2">*/}
                        {/*        <i className="icon icon-menu-up gx-text-geekblue gx-mr-2 gx-pt-1"/>*/}
                        {/*        <h2 className="gx-text-geekblue">{igAccountInfo?.igAccountInfo?.average_likes}</h2>*/}
                        {/*    </div>*/}
                        {/*    <p className="gx-text-left gx-text-grey gx-ml-2">Avg Likes/Post</p>*/}
                        {/*</Col>*/}
                        {/*<Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">*/}
                        {/*    <div className="gx-d-flex gx-flex-row gx-ml-2">*/}
                        {/*        <i className="icon icon-menu-up gx-text-geekblue gx-mr-2 gx-pt-1"/>*/}
                        {/*        <h2 className="gx-text-geekblue">{igAccountInfo?.igAccountInfo?.average_comments}</h2>*/}
                        {/*    </div>*/}
                        {/*    <p className="gx-text-left gx-text-grey gx-ml-2">Avg Comments/Post</p>*/}
                        {/*</Col>*/}
                    </Row>

                </Widget> : ''
            }
        </>
    );
};

export default IgAccountInfo;
