import React, {Fragment, useEffect} from "react";
import {Alert, Button, Col, Form, Row, Spin} from "antd";
import Biography from "../../../components/profileTalent/Biography/index";
import Contact from "../../../components/profileTalent/Contact/index";
import Photos from "../../../components/profileTalent/Photos/index";
import Auxiliary from "../../../util/Auxiliary";
import ProfileHeader from "../../../components/profileTalent/ProfileHeader/index";
import Error404 from "../../profile-ad-info/page-404";
import {EngagementRateInfo} from "../../../components/EngagementRateInfo/EngagementRateInfo";
import FollowersEvolution from "../../../components/FollowersEvolution/FollowersEvolution";
import siteConfig from "../../../config/site.config";
import BaseApiService from "../../../util/BaseApiService";
import UpdateProfile from "./update-profile";
import EngagementRateEvolution from "../../../components/EngagementRateEvolution/EngagementRateEvolution";
import Loader from "../../compain-manager/loader";

const baseApiService = new BaseApiService();

const TalentProfile = (props) => {
    const [talent, setTalent] = React.useState({});
    const [username, setUsername] = React.useState(props.match.params.username);
    const [user, setUser] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [pictures, setPictures] = React.useState([]);
    const [showDrawerUpdate, setShowDrawerUpdate] = React.useState(false);
    const [form] = Form.useForm();
    const [picturesProps, setPicturesProps] = React.useState({
        listType: 'picture',
        defaultFileList: [...pictures],
    });

    useEffect(() => {
        getTalentInfo()
    }, [username]);

    useEffect(() => {
        let formData = {...talent, ...{}};
        form.setFieldsValue(formData);
    }, [talent]);

    const getTalentInfo = () => {
        baseApiService.getMe()
            .then(res => {
                setIsLoading(false);
                const {
                    user: userData,
                    profile: talentData,
                } = res.data.data;
                setTalent(talentData || null);
                setUser(userData || null);
            });
    }

    useEffect(() => {
        let formData = {...talent, ...{}};
        form.setFieldsValue(formData);
    }, [talent]);

    useEffect(() => {
        getTalentPictures()
    }, [talent]);

    const getTalentPictures = () => {
        let TalentInfo = {...talent, ...{}};
        setPictures([]);
        picturesProps.defaultFileList = [];
        if (TalentInfo && TalentInfo.pictures && TalentInfo.pictures.length !== 0) {
            TalentInfo.pictures.map((id, idx) => {
                let item_picture = {
                    id: id,
                    uid: idx,
                    url: siteConfig.AppConfig.baseStyleImageUrl + id
                }
                const findIdx = pictures.findIndex(item => item.id === id)
                if (findIdx === -1) {
                    pictures.push(item_picture)
                    setPictures(pictures)
                    picturesProps.defaultFileList.push(item_picture)
                    setPicturesProps(picturesProps)
                }
            });
        }
    };

    const disableDrawerUpdate = (event) => {
        setShowDrawerUpdate(event);
        getTalentInfo()
    };
    const EnableDrawerUpdateProfile = () => {
        setShowDrawerUpdate(true)
    };

    const updateMe = () => {
        getTalentInfo()
        getTalentPictures()
    };

    return (
        <div>
            {!isLoading ?
                <Fragment>
                    {talent !== null ?
                        <Auxiliary>
                            <ProfileHeader mode={"owner"} talent_info={talent}/>

                            <div className="gx-profile-content">
                                <Row>
                                    <Col xl={16} lg={14} md={14} sm={24} xs={24}>
                                        {/*<About talent_info={talent}/>*/}
                                        <Biography talent_info={talent}/>
                                        {talent && talent.is_valid === 'Y' ?
                                            <Photos talent={talent}
                                                    photoList={talent.pictures ? talent.pictures : []} picturesIds= {pictures} updateMe={updateMe}/> : ''
                                        }
                                        <EngagementRateInfo/>
                                    </Col>

                                    <Col xl={8} lg={10} md={10} sm={24} xs={24}>
                                        <Contact talent_info={talent}/>

                                        {talent.stats_monthly ?
                                            <FollowersEvolution stats={talent?.stats_monthly}/> : ''}
                                        {talent.stats ?
                                            <EngagementRateEvolution stats={talent?.stats}/> : ''}

                                        {talent && talent.is_valid === 'Y' ?
                                            <div>
                                                <React.Fragment key={'right'}>
                                                    <Button onClick={EnableDrawerUpdateProfile} style={{
                                                        float: "right",
                                                        backgroundColor: '#002447',
                                                        color: '#ffffff'
                                                    }}>{'update'}</Button>
                                                    {showDrawerUpdate && siteConfig.IsTalent === 'true' ?
                                                        <UpdateProfile enable_update={showDrawerUpdate}
                                                                       disableDrawerUpdate={disableDrawerUpdate}
                                                                       updateMe={updateMe}/>
                                                        : ''
                                                    }
                                                </React.Fragment>
                                            </div> : ''
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Auxiliary>

                        : <Error404/>}
                </Fragment>
                :
                <Loader/>
            }
        </div>
    );
}

export default TalentProfile;


