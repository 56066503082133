import React, {Fragment, useEffect} from "react";
import {Button, Col, Form, Row} from "antd";
import Biography from "../../../components/profileAd/Biography/index";
import Contact from "../../../components/profileAd/Contact/index";
import Photos from "../../../components/profileAd/Photos/index";
import Auxiliary from "../../../util/Auxiliary";
import ProfileHeader from "../../../components/profileAd/ProfileHeader/index";
import Error404 from "../page-404";
import {makeStyles} from '@material-ui/core/styles';
import AdvertisersApiService from "../../../util/services/AdvertisersApiService";
import {EngagementRateInfo} from "../../../components/EngagementRateInfo/EngagementRateInfo";
import FollowersEvolution from "../../../components/FollowersEvolution/FollowersEvolution";
import siteConfig from "../../../config/site.config";
import UpdateProfileAd from "./update-profile-ad";
import Loader from "../../compain-manager/loader";
import EngagementRateEvolution from "../../../components/EngagementRateEvolution/EngagementRateEvolution";

const MyProfile = (props) => {
    const [form] = Form.useForm();
    const [advertiser, setAdvertiser] = React.useState({});
    const [username, setUsername] = React.useState(props.match.params.username);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pictures, setPictures] = React.useState([]);
    const [showDrawerUpdate, setShowDrawerUpdate] = React.useState(false);
    const useStyles = makeStyles({
        list: {
            width: 1000,
            maxWidth: '100%',
        },
        fullList: {
            width: 'auto',
        },
    });
    const [state, setState] = React.useState({
        right: false,
    });

    const EnableDrawerUpdateProfile = () => {
        setShowDrawerUpdate(true)
    }

    useEffect(() => {
        getAdInfo()
    }, [username]);

    useEffect(() => {
        let formData = {...advertiser, ...{}};
        form.setFieldsValue(formData);
    }, [advertiser]);

    useEffect(() => {
        getAdPictures()
    }, [advertiser]);

    const getAdInfo = () => {
        AdvertisersApiService.getMe()
            .then(res => {
                setIsLoading(false);
                setAdvertiser(res.data.data.profile || null);
            });
    };

    const getAdPictures = () => {
        let AdvertiserInfo = {...advertiser, ...{}};
        setPictures([])
        if (AdvertiserInfo && AdvertiserInfo.pictures && AdvertiserInfo.pictures.length !== 0) {
            AdvertiserInfo.pictures.map((id, idx) => {
                let item_picture = {
                    id: id,
                    uid: idx,
                    url: siteConfig.AppConfig.baseStyleImageUrl + id
                }
                const findIdx = pictures.findIndex(item => item.id === id)
                if (findIdx === -1) {
                    pictures.push(item_picture)
                    setPictures(pictures)
                }
            })
        }
    };

    const disableDrawerUpdate = (event) => {
        setShowDrawerUpdate(event);
        getAdInfo()
    };

    const updateMe = () => {
        getAdInfo()
        getAdPictures()
    };

    return (
        <div>
            {!isLoading ?
                <Fragment>
                    {advertiser ?
                        <Auxiliary>
                            <ProfileHeader advertiser_info={advertiser}/>
                            <div className="gx-profile-content">

                                <Row>
                                    <Col xl={16} lg={14} md={14} sm={24} xs={24}>
                                        {/*<About advertiser_info={advertiser}/>*/}
                                        <Biography advertiser_info={advertiser}/>
                                        {/*<Events/>*/}
                                        {advertiser && advertiser.is_valid === 'Y' ?
                                            <Photos advertiser={advertiser}
                                                    photoList={advertiser.pictures ? advertiser.pictures : []}
                                                    picturesIds={pictures}
                                                    updateMe={updateMe}/> : ''
                                        }
                                        <EngagementRateInfo/>
                                    </Col>

                                    <Col xl={8} lg={10} md={10} sm={24} xs={24}>
                                        <Contact advertiser_info={advertiser}/>

                                        {advertiser.stats_monthly ?
                                            <FollowersEvolution stats={advertiser?.stats_monthly}/> : ''}
                                        {advertiser.stats ?
                                            <EngagementRateEvolution stats={advertiser?.stats}/> : ''}

                                        {advertiser && advertiser.is_valid === 'Y' ?
                                            <div>
                                                <React.Fragment key={'right'}>
                                                    <Button onClick={EnableDrawerUpdateProfile} style={{
                                                        float: "right",
                                                        backgroundColor: '#002447',
                                                        color: '#ffffff'
                                                    }}>{'update'}</Button>
                                                    {showDrawerUpdate && (siteConfig.IsAD && siteConfig.IsAgency) === 'true' ?
                                                        <UpdateProfileAd enable_update={showDrawerUpdate}
                                                                         disableDrawerUpdate={disableDrawerUpdate}/>
                                                        : ''
                                                    }
                                                </React.Fragment>
                                            </div> : ''
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Auxiliary>
                        : <Error404/>}
                </Fragment>
                : <Loader/>}
        </div>
    );
}

export default MyProfile;
