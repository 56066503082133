import React from "react";
import {Col} from "antd";
import CollaborationPublic from "../../../../components/dashboard/Crypto/CollaborationPublic";

const CollaborationsPublic = ({publicCollaborations, pages}) =>{
  return (
    <div>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <CollaborationPublic publicCollaborations={publicCollaborations} pages={pages}/>
      </Col>
    </div>
  );
};

export default CollaborationsPublic;
