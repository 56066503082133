import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import {ConfigProvider} from 'antd';
import {IntlProvider} from "react-intl";
import jwt_decode from "jwt-decode";
import AppLocale from "../../tawa-digital-core/src/lngProvider";
import MainApp from "./MainApp";
import {
    onLayoutTypeChange,
    onNavStyleChange,
    setInitUrl,
    setThemeType
} from "../../tawa-digital-core/src/appRedux/actions";

import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    THEME_TYPE_DARK,
    THEME_TYPE_LITE
} from "../../tawa-digital-core/src/constants/ThemeSetting";
import siteConfig from "../../tawa-digital-core/src/config/site.config";
import Profiles from "../../tawa-digital-core/src/routes/profile-info";
import BaseApiService from "../../tawa-digital-core/src/util/BaseApiService";

const baseApiService = new BaseApiService();

const RestrictedRoute = ({component: Component, location, ...rest}) => {
    const token = localStorage.getItem('token');
    return <Route
        {...rest}
        render={props =>
            // token ?
                <Component {...props} />
                // : (
                //     window.location.href = siteConfig.loginUrl
                // )
        }
    />;
}

const App = (props) => {
    const dispatch = useDispatch();
    const {locale, themeType, navStyle, layoutType, themeColor} = useSelector(({settings}) => settings);
    const {authUser, initURL} = useSelector(({auth}) => auth);
    const [Token, settoken] = React.useState(null);
    const [appIsInited, setAppIsInited] = React.useState(false);
    const location = useLocation();
    const match = useRouteMatch();

    useEffect(() => {
        if (!!!appIsInited) {
            if (localStorage.getItem('token') === null) {
                const query = new URLSearchParams(props.location.search);
                const token = query.get('token');
                if (token) {
                    const decoded = jwt_decode(token || null);
                    localStorage.setItem('token', token);
                    localStorage.setItem('user_id', decoded.user_id);
                    window.location.href = siteConfig.appBaseUrl + '/me';
                }
            } else {
                settoken((localStorage.getItem('token')));
                const decoded = jwt_decode(localStorage.getItem('token'));
                localStorage.setItem('user_id', decoded.user_id);
                verifyToken();
            }
            setAppIsInited(true);
        }
    }, [appIsInited]);

    const verifyToken = () => {
        let params = {
            token: localStorage.getItem('token')
        }
        baseApiService.verifyToken(params).then(res => {
            if (true) {
            } else {
                window.location.href = siteConfig.loginUrl
            }
        }).catch(err => {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = siteConfig.loginUrl;
        })
    }

    useEffect(() => {
        if (initURL === '') {
            dispatch(setInitUrl(location.pathname));
        }
        const params = new URLSearchParams(location.search);

        if (params.has("theme")) {
            dispatch(setThemeType(params.get('theme')));
        }
        if (params.has("nav-style")) {
            dispatch(onNavStyleChange(params.get('nav-style')));
        }
        if (params.has("layout-type")) {
            dispatch(onLayoutTypeChange(params.get('layout-type')));
        }
        setLayoutType(layoutType);
        setNavStyle(navStyle);
    });


    const setLayoutType = (layoutType) => {
        if (layoutType === LAYOUT_TYPE_FULL) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('full-layout');
        } else if (layoutType === LAYOUT_TYPE_BOXED) {
            document.body.classList.remove('full-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('boxed-layout');
        } else if (layoutType === LAYOUT_TYPE_FRAMED) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('full-layout');
            document.body.classList.add('framed-layout');
        }
    };

    const setNavStyle = (navStyle) => {
        if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
            navStyle === NAV_STYLE_DARK_HORIZONTAL ||
            navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
            navStyle === NAV_STYLE_ABOVE_HEADER ||
            navStyle === NAV_STYLE_BELOW_HEADER) {
            document.body.classList.add('full-scroll');
            document.body.classList.add('horizontal-layout');
        } else {
            document.body.classList.remove('full-scroll');
            document.body.classList.remove('horizontal-layout');
        }
    };

    // useEffect(() => {
    //   if (location.pathname === '/') {
    //     if (authUser === null) {
    //       history.push('/signin');
    //     } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
    //       history.push('/');
    //     } else {
    //       history.push(initURL);
    //     }
    //   }
    // }, [authUser, initURL, location, history]);

    useEffect(() => {
            onThemeTypeChange()
            if (themeType === THEME_TYPE_DARK) {
                console.log("adding dark class")
                document.body.classList.add('dark-theme');
                document.body.classList.add('dark-theme');
                let link = document.createElement('link');
                link.type = 'text/css';
                link.rel = 'stylesheet';
                link.href = "/css/dark_theme.css";
                link.className = 'style_dark_theme';
                document.body.appendChild(link);
            }
        }
        , []);
    const onThemeTypeChange = (e) => {
        const current_theme = THEME_TYPE_LITE
        dispatch(setThemeType(current_theme));
    };

    const currentAppLocale = AppLocale[locale.locale];

    return (
        <ConfigProvider>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <Switch>
                    <Route exact path="/">
                        <Redirect to={"/me"}/>
                    </Route>
                    <RestrictedRoute strict={true} path={`/me`} component={MainApp}/>
                    <Route component={Profiles}/>
                </Switch>
            </IntlProvider>
        </ConfigProvider>
    )
};

export default memo(App);
