import React, {Fragment, useEffect} from "react";
import {tawaCities} from "../../../util/cities";
import {tawaAges} from "../../../util/ages";
import {tawaGender} from "../../../util/gender";
import {tawaEngagement_rate} from "../../../util/engagementRate";
import {tawaFollowers_count} from "../../../util/followersCount";
import {Button, Card, Col, Form, Input, message, Row, Select, Table} from "antd";
import categoriesApiService from "../../../util/services/CategoriesApiService";
import siteConfig from "../../../config/site.config";
import {abbrNum, pourcentNum, unaccent} from "../../../util/helpers";
import {SocialIcon} from "react-social-icons";
import Widget from "../../../components/Widget";
import talentApiService from "../../../util/services/TalentsApiService";
import DateCountdown from 'react-date-countdown-timer';
import IconWithTextCard from "../../../components/dashboard/IconWithTextCard";
import AdvertisersApiService from "../../../util/services/AdvertisersApiService";
import advertiserApiService from "../../../util/services/AdvertisersApiService";
import {useIntl} from "react-intl";
import IntlMessages, {translateMsg} from "../../../util/IntlMessages";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import Loader from "../../compain-manager/loader";


const Search = Input.Search;
const locales = ['year', 'month', 'day', 'hour']
const AdvancedSearch = (props) => {
    const [Talents, setTalents] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [initApp, setInitApp] = React.useState(true);
    const [meta_key, setMeta_key] = React.useState('');
    const [appIsInited, setAppIsInited] = React.useState(false);
    const [filtres, setFiltres] = React.useState(null);
    const [filtresCategories, setFiltresCategories] = React.useState(null);
    const [disableFilter, setDisableFilter] = React.useState(false);
    const [trialDate, setTrialDate] = React.useState('');
    const [formFilter] = Form.useForm();
    const intl = useIntl();
    const [agency, setAgency] = React.useState({});
    const filterElements = [
        {
            idx: 0,
            label: 'Categories',
            value: 'categories',
            data: categories,
            key_value: 'category_id'
        },
        {
            idx: 1,
            label: 'Ville',
            value: 'ville',
            data: tawaCities,
            key_value: 'value'
        },
        {
            idx: 2,
            label: 'Age',
            value: 'age_intervale',
            data: tawaAges,
            key_value: 'value'
        },
        {
            idx: 3,
            label: 'Gender',
            value: 'gender',
            data: tawaGender,
            key_value: 'value'
        },
        {
            idx: 4,
            label: 'Engagement rate',
            value: 'engagement_rate',
            data: tawaEngagement_rate,
            key_value: 'value'
        },
        {
            idx: 5,
            label: 'Followers count',
            value: 'followers_count',
            data: tawaFollowers_count,
            key_value: 'value'
        },
    ];
    const InfoTalents = [
        {
            title: 'Talent Picture',
            dataIndex: 'profile_efile_id',
            render: (text, record) => {
                return <div className="gx-flex-row gx-align-items-center">
                    <img className="gx-rounded-circle gx-size-30 gx-mr-2"
                         src={siteConfig.AppConfig.baseStyleImageUrl + text}
                         alt=""/>
                </div>
            },
        },
        {
            title: 'Talent Name ',
            render: (text, record) => {
                return <div className="gx-flex-row gx-align-items-center">
                    <span className="gx-mb-0"><a href={siteConfig.TalentUrl + '/' + record?.username}
                                                 target="_blank">{record?.influencer_name}</a></span>
                </div>
            },
        },
        {
            title: 'Engagement rate',
            dataIndex: 'engagement_rate',
            render: (text, record) => {
                return <span className="gx-text-grey">{pourcentNum(record?.engagement_rate, 2) + '%'}</span>
            },

        },
        {
            title: 'Followers',
            dataIndex: 'Followers',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{abbrNum(record?.followers_count, 2)}</span>
            },
        },
        {
            title: 'Instagram Account',
            dataIndex: 'instagram_url',
            render: (text, record) => {
                return <span className="gx-text-grey"> <a target={"_blank"}
                                                          href={"https://instagram.com/" + record?.ig_username}><SocialIcon
                    network={"instagram"}/></a></span>
            },

        },
    ];

    useEffect(() => {
        getAGInfo()
    }, [initApp]);
    const getAGInfo = () => {
        AdvertisersApiService.getMe()
            .then(res => {
                let profile_info = res.data.data.profile;
                setTrialDate(profile_info?.free_trial_expire_date);
                setAgency(profile_info || null);
                setAppIsInited(true);
            });
    };

    const getFieldsAdvancedSearch = () => {
        const fieldsSearch = [];

        filterElements.forEach(item_filter => {
            fieldsSearch.push(
                <Col lg={8} md={8} sm={12} xs={24} key={item_filter.idx} style={{display: 'block'}}>
                    <div className="gx-form-row0">
                        <Form.Item
                            name={item_filter.value}
                            label={item_filter.label}
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                style={{width: 400}}
                                placeholder={"Select a " + item_filter.label}
                                optionFilterProp="children"
                                filterOption={(input, option) => unaccent(option.props.children.toLowerCase()).indexOf(unaccent(input.toLowerCase())) >= 0}
                            >
                                {item_filter?.data?.map((option, idx) => {
                                    return <Select.Option value={option[item_filter.key_value]} key={idx}>{option.label}</Select.Option>
                                })}

                            </Select>
                        </Form.Item>
                    </div>
                </Col>,
            );
        })

        return fieldsSearch;
    };

    useEffect(() => {
        categoriesApiService.find({limit: -1}).then(res => {
            setCategories(res.data.data || []);
        });
    }, [initApp]);

    const onFiltreTalentsByCode = (code) => {
        if (code && code !== '') {
            setMeta_key(code);
            let filter_values = {...filtres};
            filter_values.meta_key = code;
            getTalents(filter_values, filtresCategories)
        } else {
            let filter_values = {...filtres};
            filter_values.meta_key = null;
            getTalents(filter_values, filtresCategories)
            message.error('please put a talent name')
        }
    };

    const getTalents = (filter, categories) => {
        setFiltres(filter);
        setFiltresCategories(categories)
        filter.is_valid = false;
        talentApiService
            .getTalents({
                limit: -1,
                categories: categories ? categories.categories : null,
                filter: filter
            })
            .then(res => {
                let dataTalents = res.data.data || [];
                dataTalents.forEach(t => {
                    t.key = t.influencer_id;
                });
                setTalents(dataTalents);
                handleChangeSearchCount();
                setAppIsInited(true);
            });
    };

    const handleChangeSearchCount = () => {
        const formAgency = {search_count: agency.search_count + 1};
        formAgency.advertiser_id = agency.advertiser_id;
        if (formAgency.search_count > siteConfig.Max_request || trialDate < moment(new Date()).format('YYYY-MM-DD')) {
            setTalents([]);
            setDisableFilter(true);
        } else {
            advertiserApiService.update(formAgency).then(res => {
                getAGInfo();
            }).catch(err => {
                const {data} = err.response;
                if (data && data.messages) {
                    data.messages.forEach(msg => {
                        message.error(translateMsg(msg, intl));
                    });
                }
            });
        }
    }
    const resetFilter = () => {
        formFilter.resetFields();
        setTalents([])
    }

    const onFiltre = values => {
        if (values.categories && values.categories.length !== 0 || values.gender && values.gender.length !== 0 || values.age_intervale && values.age_intervale.length !== 0
            || values.ville && values.ville.length !== 0 || values.engagement_rate && values.engagement_rate.length !== 0 || values.followers_count && values.followers_count.length !== 0) {
            let categories = {
                'categories': values.categories
            };
            if (values.categories || values?.categories?.length !== 0) {
                delete values['categories'];
            }
            Object.keys(values).forEach(item_filter => {
                if (values[item_filter] && values[item_filter].length === 0) {
                    delete values[item_filter]
                }
            })
            values.is_valid = ['Y']
            getTalents(values, categories)
        } else {
            setTalents([])
            message.error('please choose a filter element')
        }

    };

    const onCancelAlert = () => {
        setDisableFilter(false);
    }

    return (
        <Fragment>
            {appIsInited ?
                <>
                    <Card>
                        <Row>
                            <Col xl={12} lg={8} md={8} sm={12} xs={24}>
                                <IconWithTextCard icon="visits"
                                                  title={agency?.search_count ? agency?.search_count + '/' + siteConfig.Max_request: 0 + '/' +  siteConfig.Max_request }
                                                  cardColor="primary" subTitle="Requests"/>
                            </Col>
                            {
                                trialDate ?
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="ml-auto mr-3">
                                        <div className={"gx-mb-3"} style={{textAlign: "center"}}>
                                            <IconWithTextCard cardColor="red" icon="calendar-new"
                                                              title={<DateCountdown dateTo={trialDate}/>}/>
                                        </div>
                                    </Col>:''
                            }
                        </Row>
                    </Card>
                    <Card className="gx-card" title="Advanced Search For Talents">
                        <Form
                            form={formFilter}
                            name="advanced_search_talents"
                            className="ant-advanced-search-talents-form"
                            onFinish={onFiltre}
                        >
                            <Row gutter={24}>{getFieldsAdvancedSearch()}</Row>
                            <Row>
                                <Col span={24} style={{textAlign: 'right'}}>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            resetFilter()
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                    <Widget
                        title={
                            <h2 className="h4 gx-text-capitalize gx-mb-0">
                                List Talents</h2>
                        }>
                        <Search
                            placeholder="input talent name"
                            onSearch={value => onFiltreTalentsByCode(value)}
                            style={{width: 200}}
                        />
                        <div className="gx-table-responsive">
                            <Table className="gx-table-no-bordered" columns={InfoTalents} dataSource={Talents}
                                   pagination={true}
                                   size="small"/>
                        </div>
                    </Widget>
                    <SweetAlert show={disableFilter}
                                warning
                                cancelBtnBsStyle="default"
                                title={<IntlMessages id="sweetAlerts.limitedRequest"/>}
                                onConfirm={onCancelAlert}
                    >
                        <IntlMessages id="sweetAlerts.contactAdmin" /> <h5>midox@tawa.digital</h5>
                    </SweetAlert>
                </> :
                <Loader/>
            }
        </Fragment>
    )
}

export default AdvancedSearch;
