import React, {Fragment, useEffect} from "react";
import {Col, Input, Popover, Row} from "antd";
import CompainItem from "./../../compain-manager/CompainItem";
import {Link} from "react-router-dom";
import siteConfig from "../../../config/site.config";
import {useIntl} from "react-intl";
import Pagination from "@material-ui/lab/Pagination";
import compainApiService from "../../../util/services/CompainApiService";
import Loader from "../../compain-manager/loader";

const Search = Input.Search;

const AdminCompainManager = (props) => {
    const [allCompains, setAllCompainS] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pages, setPages] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0)
    const [offset, setOffset] = React.useState(0)
    const [meta_key, setMeta_key] = React.useState('')

    const intl = useIntl();

    useEffect(() => {
        getAllCompains(page)
    }, [true]);

    const getAllCompains = (page) => {
        compainApiService
            .find({
                limit: 8,
                page: page,
                sortBy: 'created_at',
                sortDir: 'DESC',
            })
            .then(res => {
                setIsLoading(false);
                setAllCompainS(res.data.data || null);
                setPages(res.data.attributes.pages);
                setOffset(res.data.attributes.offset);
                setCount(res.data.attributes.count)
            });
    };

    const onFilterCompainsByCode = (code) => {
        setMeta_key(code)
        if (code && code !== '' && code.toString().length > 3) {
            compainApiService
                .find({
                    limit: -1,
                    meta_key: code.toString(),
                    sortBy: 'created_at',
                    sortDir: 'DESC',
                })
                .then(res => {
                    setIsLoading(false);
                    setAllCompainS(res.data.data || null);
                    setPages(res.data.attributes.pages);
                    setOffset(res.data.attributes.offset);
                    setCount(res.data.attributes.count)
                });
        } else {
            getAllCompains(page);
        }
    };

    const onChangePage = (event, newPage) => {
        let current_page = newPage
        setPage(current_page)
        getAllCompains(current_page)
    };
    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li><Link to={siteConfig.prefixMe + "/add-public-compain"}>Add public compain</Link></li>
            <li><Link to={siteConfig.prefixMe + "/add-private-compain"}>Add private compain</Link></li>
        </ul>
    );

    const updateMe = () => {
        getAllCompains(page);
    };
    return (
        <div>
            {!isLoading ?
                <>
                    <Fragment>
                        <div className={"gx-mb-3"} style={{textAlign: "right"}}>
                            <button className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-text-uppercase ">
                                <Popover content={userMenuOptions}>
                                    Add
                                </Popover>
                            </button>
                        </div>
                        <Search
                            enterButton="Search" size="large"
                            placeholder="input compain name"
                            onSearch={value => onFilterCompainsByCode(value)}
                            style={{width: 500}}
                        />
                        <Row>
                            {allCompains.map((compain, index) => (<Col key={index} xl={6} md={8} sm={12} xs={24}>
                                    <CompainItem key={index} grid compain={compain} updateMe={updateMe}/>
                                </Col>
                            ))}
                        </Row>
                        <Pagination style={{float: "right"}} count={pages} color="primary"
                                    onChange={onChangePage}/>

                    </Fragment>
                </> :
                <Loader/>
            }
        </div>
    );
};

export default AdminCompainManager;


