import React, {useEffect, useState} from "react";
import {Avatar, message} from "antd";
import siteConfig from "../../../config/site.config";
import {abbrNum, isMyAccount, pourcentNum} from "../../../util/helpers";
import FBValidatorConnector from "../../FBComponents/FBValidatorAccount";
import {translateMsg} from "../../../util/IntlMessages";
import {useIntl} from "react-intl";
import HeaderProfileIGData from "../../profile/HeaderProfileIGData/HeaderProfileIGData";
const ProfileHeader  = ({advertiser_info}) =>{
  const [advertiser, setAdvertiser] = useState(advertiser_info);
  const [ig_data, setIgData] = useState(advertiser_info.ig_data);
  const intl = useIntl();

  useEffect(() => {
    setAdvertiser(advertiser_info);
    setIgData(advertiser.ig_data);
  }, [advertiser_info]);

  const callbackFBConnector = (data) => {
    setAdvertiser({...advertiser, ...{is_valid: 'Y'}});
    message.success(translateMsg("AccountValidatedWithSuccess", intl), 3, () => {
      window.location.reload();
    });
  }

  return (
    <div className="gx-profile-banner">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar">
              <Avatar className="gx-size-90" alt="" src={siteConfig.AppConfig.baseStyleImageUrl + advertiser_info?.profile_efile_id} />
            </div>
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-fs-xxl gx-font-weight-light">
                <span>{advertiser?.advertiser_name}</span>
                {advertiser.is_valid === "Y" ? <img alt={"Verified account"} className={"gx-ml-3"}
                                                src={require("../../../assets/images/check.svg")} width={28}/> : <span className={"unverified-span"}>(non vérifié)</span>}
              </h2>
            </div>
          </div>

          <div className="gx-profile-banner-top-right">
            <HeaderProfileIGData ig_data={advertiser_info.ig_data} />
          </div>
        </div>
      </div>

      {advertiser.is_valid !== "Y" && isMyAccount(advertiser) ? <div className={"gx-mb-3"} style={{textAlign: "center"}}>
        <p>Connectez-vous en utilisant Instagram pour vérifier votre compte</p>
        <FBValidatorConnector profileType={"advertiser"} callbackFBConnector={(data) => {
          callbackFBConnector(data);
        }} />
      </div> : ''}
    </div>
  )
};

export default ProfileHeader;
