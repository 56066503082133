import React, {Fragment, useEffect} from "react";
import {Alert, Card, Col, message, Row, Spin} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import MesCollaborations from "./MesCollaborations";
import talentApiService from "../../../util/services/TalentsApiService";
import {useIntl} from "react-intl";
import IntlMessages, {translateMsg} from "../../../util/IntlMessages";
import SweetAlert from "react-bootstrap-sweetalert";
import {isMyAccount} from "../../../util/helpers";
import FBValidatorConnector from "../../../components/FBComponents/FBValidatorAccount";
import Button from "@material-ui/core/Button";
import Loader from "../../compain-manager/loader";

const Collaboration = (props) => {
    const [Collaborations, setCollaborations] = React.useState([]);
    const [talent_id, setTalent_id] = React.useState(null);
    const [talent, setTalent] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [showAlert, setShowAlert] = React.useState(true);
    const [is_valid, setIsValid] = React.useState(localStorage.getItem('is_valid') || null);
    const intl = useIntl();

    useEffect(() => {
        setTalent_id(localStorage.getItem('talent_id') ? localStorage.getItem('talent_id') : null)
        talentApiService
            .getAllInvitationByTalent(talent_id)
            .then(res => {
                setIsLoading(false);
                setCollaborations(res.data.data || null);
            });
    }, [talent_id]);

    useEffect(() => {
        talentApiService
            .getMe()
            .then(res => {
                setTalent(res.data.data.profile || null);
            });
    }, [talent_id]);

    const callbackFBConnector = (data) => {
        setTalent({...talent, ...{is_valid: 'Y'}});
        message.success(translateMsg("AccountValidatedWithSuccess", intl), 3, () => {
            window.location.reload();
        });
    }
    const disableAlert = () =>{
        setShowAlert(false)
    }
    return (
        <div>
            {!isLoading ?
                <>
                    {is_valid === 'Y' ?
                        <Fragment>
                            <Auxiliary>
                                <Row>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <MesCollaborations collaborations={Collaborations}/>
                                    </Col>
                                </Row>

                            </Auxiliary>
                        </Fragment>
                        : <SweetAlert show={showAlert}
                                      showCancel={false}
                                      showConfirm={false}
                                      title={<IntlMessages id="ad.accountNotValid"/>}
                        >
                            {talent?.is_valid !== "Y" && isMyAccount(talent) ?
                                <div className={"gx-mb-3"} style={{textAlign: "center"}}>
                                    <p>Connectez-vous en utilisant Instagram pour vérifier votre compte</p>
                                    <Button onClick={disableAlert}>
                                        <FBValidatorConnector profileType={"talent"} callbackFBConnector={(data) => {
                                            callbackFBConnector(data);
                                        }}/>
                                    </Button>

                                </div> : ''}
                        </SweetAlert>}
                </> :
                <Loader/>
            }
        </div>
    );
};

export default Collaboration;
