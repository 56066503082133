import React, { useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { Button, Card, Checkbox, Form, Input, Modal, Radio, Upload } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import BaseApiService from "../../../../util/BaseApiService";
import IntlMessages, { translateMsg } from "../../../../util/IntlMessages";
import categoriesApiService from "../../../../util/services/CategoriesApiService";
import { message } from "antd/lib/index";
import { useIntl } from "react-intl";
import { post } from "axios";
import siteConfig from "../../../../config/site.config";
import { UploadOutlined } from "@ant-design/icons";
import { tawaAge } from "../../../../util/gender";
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import { countryApiService } from "../../../../util/services/CountryService";

const baseApiService = new BaseApiService();
const FormItem = Form.Item;
const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})
const AddTalent = ({ enable_add, disableDrawerAdd, is_valid }) => {
    const [talent, setTalent] = React.useState({});
    const [form] = Form.useForm();
    const [selectedVille, setSelectedVille] = React.useState('');
    const [selectedGender, setSelectedGender] = React.useState(null);
    const [age, setAge] = React.useState('');
    const [categories, setCategories] = React.useState([]);
    const [selectCategories, setSelectedCategories] = React.useState([]);
    const [initApp, setInitApp] = React.useState(true);
    const [updateIgUsername, setUpdateIgUsername] = React.useState(false);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewPicture, setPreviewPicture] = React.useState('');
    const [talent_efile_id, setTalent_efile_id] = React.useState();
    const [citiesOptions, setCitiesOptions] = React.useState([]);
    const [ageOptions, setAgeOptions] = React.useState([]);
    const [selectedCountryOfOrigin, setSelectedCountryOfOrigin] = React.useState(null);
    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const intl = useIntl();
    const [countries, setCountries] = React.useState([]);

    useEffect(() => {
        setAgeOptions(tawaAge.map(item => ({
            value: item.value,
            label: item.label
        })));

        categoriesApiService.find({ limit: -1 }).then(res => {
            setCategories(res.data.data || []);
        });
        
        categoriesApiService.find({ limit: -1 }).then(res => {
            setCategories(res.data.data || []);
        });

        countryApiService.find().then(res => {
            const { data } = res.data
            const newCountryList = data.map(item => ({
                value: item.isoCode,
                label: item.name
            }))
            setCountries(newCountryList)
        })
    }, [])

    const handleChangeCountryOfOrigin = (formItem) => {
        setSelectedCountryOfOrigin(formItem);

        form.setFieldsValue({
            country_of_origin: formItem?.value || null
        })
    };

    const [state, setState] = React.useState({
        right: enable_add,
    });
    const useStyles = makeStyles({
        list: {
            width: 1000,
            maxWidth: '100%',
        },
        fullList: {
            width: 'auto',
        },
    });

    const classes = useStyles();

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const handleChangeVille = (value) => {
        setSelectedVille(value);
        form.setFieldsValue({
            ville: value?.value || null
        })
    };


    const handleChangeCountry = (formItem) => {
        setSelectedCountry(formItem);

        form.setFieldsValue({
            country: formItem?.value || null
        })

        if (formItem.value) {
            form.setFieldsValue({
                country: formItem?.value || null
            })

            let allCitites = []
            countryApiService.getStates(formItem.value).then((res) => {
                const { data } = res.data
                const newStates = data.map(state => { return { value: state.name, label: state.name } })
                allCitites = [...allCitites, ...newStates]
                setCitiesOptions(allCitites);
            })
            setSelectedVille(null);
        } else {
            setCitiesOptions([]);
            setSelectedVille(null);
        }
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            disableDrawerAdd(false)
            return;
        }
        setState({ ...state, [anchor]: open });
        disableDrawerAdd(false)

    };

    const onFinish = (values) => {
        const formProfile = values
        formProfile.categories = selectCategories;
        formProfile.ville = selectedVille?.label || null;
        formProfile.country = selectedCountry?.value || null;
        formProfile.country_of_origin = selectedCountryOfOrigin?.value || null;
        formProfile.gender = selectedGender;
        formProfile.age = age?.value || null;
        formProfile.profile_efile_id = talent_efile_id;
        formProfile.is_valid = is_valid;
        formProfile.is_verified = 'Y'

        baseApiService.addTalent(formProfile).then(res => {
            message.success('Account created with success', 3)
            disableDrawerAdd(false)

        }).catch(err => {
            const { data } = err.response;
            if (data && data.messages) {
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    };

    const handleChangeAge = (value) => {
        setAge(value);
        form.setFieldsValue({
            age_intervale: value?.value || null
        })
    };

    const handleChangeCategories = (selected_categories) => {
        setSelectedCategories(selected_categories);
    };

    const handleUploadClick = event => {
        const file = event.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            return post(siteConfig.AppConfig.fileUploadUrl, formData, {
                headers: {
                    'Content-Type': `multipart/form-data;`,
                },
                timeout: 30000,
            }).then(res => {
                setTalent_efile_id(res.data.data)
            })
        }
    };

    const handleCancel = () => {
        setPreviewVisible(false)
    }

    const handlePreview = (file) => {
        setPreviewPicture(file.url || file.thumbUrl)
        setPreviewVisible(true)
    };

    const talentPicture = [{
        uid: -1,
        name: 'talent picture',
        status: 'done',
        url: siteConfig.AppConfig.baseStyleImageUrl + talent_efile_id,
    }]

    const handleChange = () => {
        talent_efile_id(null)
    }

    const FormProfile = (anchor) => {
        return (<div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
            <Card className="gx-card" title="Add talent">
                <Form
                    {...formItemLayout}
                    form={form}
                    name="update"
                    onFinish={onFinish}
                    initialValues={{
                        prefix: '216',
                    }}
                    scrollToFirstError
                >
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your user name!',
                            },
                        ]}

                    >
                        <Input disabled={updateIgUsername} />
                    </Form.Item>
                    <FormItem
                        name="first_name"
                        label="First Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your First name!',
                            },
                        ]}
                    >
                        <Input />
                    </FormItem>
                    <FormItem
                        name="last_name"
                        label="Family Name"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your Last name!',
                            },
                        ]}
                    >
                        <Input />
                    </FormItem>
                    <FormItem
                        name="phone"
                        label="Phone Number"
                        rules={[{ required: false, message: 'Please input your phone number!' }]}
                    >
                        <Input style={{ width: '100%' }} />
                    </FormItem>
                    <FormItem
                        name="user_email"
                        label="Email"
                        disabled={true}
                    >
                        <Input style={{ width: '100%' }} />
                    </FormItem>
                    <FormItem
                        name="ig_username"
                        label="Instagram user name"
                    >
                        <Input style={{ width: '100%' }} />
                    </FormItem>
                    <FormItem
                        name="tiktok_username"
                        label="Tiktok user name"
                    >
                        <Input style={{ width: '100%' }} />
                    </FormItem>
                    <FormItem
                        name="country_of_origin"
                        label="Country of origin"
                        rules={[{ required: true, message: 'Please select your country of origin!' }]}
                    >
                        <Row>
                            <Col md='6'>
                                <Select
                                    value={selectedCountryOfOrigin}
                                    theme={selectThemeColors}
                                    onChange={(value) => handleChangeCountryOfOrigin(value)}
                                    options={countries}
                                />
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem name="country"
                        label="Country of residence"
                        rules={[{ required: true, message: 'Please select your Country of residence!' }]}
                    >
                        <Row>
                            <Col md='6'>
                                <Select
                                    value={selectedCountry}
                                    theme={selectThemeColors}
                                    onChange={(value) => handleChangeCountry(value)}
                                    options={countries}
                                />
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem name="ville"
                        label="City"
                        rules={[{ required: true, message: 'Please select your ville!' }]}>
                        <Row>
                            <Col md='6'>
                                <Select
                                    theme={selectThemeColors}
                                    value={selectedVille}
                                    onChange={(value) => handleChangeVille(value)}
                                    options={citiesOptions}
                                />
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem
                        name="gender"
                        label="Gender"
                        rules={[{ required: false, message: 'Please select your gender!' }]}
                    >
                        <div className={"mb-3"}>
                            <Radio.Group onChange={(e) => setSelectedGender(e.target?.value || null)} value={selectedGender}>
                                <Radio value={null}>Prefer not to say</Radio>
                                <Radio value={"M"}>Man</Radio>
                                <Radio value={"F"}>Women</Radio>
                            </Radio.Group>
                        </div>
                    </FormItem>
                    <FormItem
                        name="age_intervale"
                        label="Age"
                        rules={[{ required: false, message: 'Please select your age interval!' }]}
                    >
                        <Row>
                            <Col md='6'>
                                <Select
                                    theme={selectThemeColors}
                                    onChange={(value) => handleChangeAge(value)}
                                    id="age"
                                    value={age}
                                    options={ageOptions}
                                    isClearable={true}
                                />
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem
                        name="categories"
                        label="Categories"
                        rules={[{ required: true, message: 'Please select your Categories!' }]}
                    >
                        <Checkbox.Group onChange={handleChangeCategories}
                            defaultValue={categories}>
                            {categories.map(option => {
                                return <Checkbox className={"gx-ml-0 gx-mr-1 gx-mb-1"} key={option.value}
                                    value={option.category_id}>{option.label}</Checkbox>
                            })}
                        </Checkbox.Group>
                    </FormItem>
                    <FormItem
                        name="user_passwordhash"
                        label="Password"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password autoComplete={"new-password"} />
                    </FormItem>
                    <FormItem
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['user_passwordhash']}
                        hasFeedback
                        rules={[
                            {
                                required: false,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if ((!value && !getFieldValue('user_passwordhash')) || getFieldValue('user_passwordhash') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password autoComplete={"new-password"} />
                    </FormItem>
                    <FormItem
                        label={"Talent picture"}>
                        <div className={"group-file-upload"}>
                            <input
                                accept="image/*"
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleUploadClick}
                            />
                            <Button icon={<UploadOutlined />} onClick={() => {
                                document.getElementById('contained-button-file').click()
                            }}>Click to upload</Button>
                        </div>
                    </FormItem>
                    {talent_efile_id ?
                        <Card title="Talent picture" className="gx-card clearfix">
                            <Upload
                                listType="picture-card"
                                fileList={talentPicture}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                            </Upload>
                            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                <img alt="Talent picture" style={{ width: '100%' }} src={previewPicture} />
                            </Modal>
                        </Card> : ''
                    }

                    <FormItem {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            save
                        </Button>
                        <Button type="secondary" onClick={() => disableDrawerAdd(false)}><IntlMessages
                            id="drawer.cancel" /></Button>
                    </FormItem>
                </Form>
            </Card>

        </div>
        );
    };

    return (
        <Drawer anchor={'right'} open={state['right']}
            onClose={toggleDrawer('right', false)}>
            {FormProfile('right')}
        </Drawer>
    )
};

export default AddTalent;
