import React, {useEffect} from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import {Button, Card, Checkbox, Form, Input, Modal, Upload} from "antd";
import {makeStyles} from "@material-ui/core/styles";
import BaseApiService from "../../../../util/BaseApiService";
import IntlMessages, {translateMsg} from "../../../../util/IntlMessages";
import Select from '@material-ui/core/Select';
import {tawaCities} from "../../../../util/cities";
import categoriesApiService from "../../../../util/services/CategoriesApiService";
import {message} from "antd/lib/index";
import {useIntl} from "react-intl";
import businessSectorsApiService from "../../../../util/services/BusinessSectorsApiService";
import {post} from "axios";
import siteConfig from "../../../../config/site.config";
import {UploadOutlined} from "@ant-design/icons";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
const baseApiService = new BaseApiService();

const AddAd = ({enable_add, disableDrawerAdd, category, profile_id, title, is_agency}) => {
    const [advertiser, setAdvertiser] = React.useState({});
    const [form] = Form.useForm();
    const [selectedVille, setSelectedVille] = React.useState([]);
    const [selectedGender, setSelectedGender] = React.useState(null);
    const [age, setAge] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [selectCategories, setSelectedCategories] = React.useState({});
    const [businessSector, setBusinessSector] = React.useState([]);
    const [selectedCible, setselectedCible] = React.useState([]);
    const [selectedBusinessSector, setSelectedBusinessSector] = React.useState(null);
    const [updateIgUsername, setUpdateIgUsername] = React.useState(false)
    const [initApp, setInitApp] = React.useState(true);
    const intl = useIntl();
    const [stateDrawer, setStateDrawer] = React.useState(enable_add);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewPicture, setPreviewPicture] = React.useState('');
    const [ad_efile_id, setAd_efile_id] = React.useState();

    const useStyles = makeStyles({
        list: {
            width: 1000,
            maxWidth: '100%',
        },
        fullList: {
            width: 'auto',
        },
    });
    const classes = useStyles();
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    useEffect(() => {
        categoriesApiService.find({limit: -1}).then(res => {
            setCategories(res.data.data || []);
        });
    }, [initApp]);

    useEffect(() => {
        businessSectorsApiService.find({limit: -1}).then(res => {
            setBusinessSector(res.data.data || []);
        });
    }, [initApp]);

    const handleChangeVille = (event) => {
        event.persist()
        setSelectedVille(event.target.value)
        form.setFieldsValue({
            ville: event.target.value || null
        })
    };

    const handleChangeBusinessSector = (event) => {
        event.persist()
        setSelectedBusinessSector(event.target.value)
        form.setFieldsValue({
            business_sector_id: event.target.value || null
        })
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        disableDrawerAdd(false);
        setStateDrawer(open);
    };

    const onFinish = values => {
        const formProfile = values;
        formProfile.business_sector_id = selectedBusinessSector;
        formProfile.ville = selectedVille;
        formProfile.ages = age;
        formProfile.cible = selectedCible;
        formProfile.profile_id = parseInt(profile_id);
        formProfile.profile_efile_id = ad_efile_id;
        formProfile.category = category;
        formProfile.is_valid = 'Y';
        baseApiService.addAd(formProfile).then(res => {
            message.success('Account created with success', 3);
            disableDrawerAdd(false)
        }).catch(err => {
            const {data} = err.response;
            if (data && data.messages) {
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    };

    const handleUploadClick = event => {
        const file = event.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            return post(siteConfig.AppConfig.fileUploadUrl, formData, {
                headers: {
                    'Content-Type': `multipart/form-data;`,
                },
                timeout: 30000,
            }).then(res => {
                setAd_efile_id(res.data.data)
            })
        }
    };
    const handleCancel = () => {
        setPreviewVisible(false)
    }
    const handlePreview = (file) => {
        setPreviewPicture(file.url || file.thumbUrl)
        setPreviewVisible(true)
    };
    const adPicture = [{
        uid: -1,
        name: 'advertiser picture',
        status: 'done',
        url: siteConfig.AppConfig.baseStyleImageUrl + ad_efile_id,
    }]

    const handleChange = () => {
        ad_efile_id(null)
    }

    const handleChangeAge = (event) => {
        setAge(event.target.value);
        form.setFieldsValue({
            ages: event.target.value || null
        })
    };

    const handleChangeCategories = (selected_categories) => {
        setSelectedCategories(selected_categories);
    };

    const handleChangeCible = (selected_cible) => {
        setselectedCible(selected_cible);
        form.setFieldsValue({
            cible: selected_cible || null
        })
    };

    const FormProfile = (anchor) => {
        return (<div
                className={clsx(classes.list, {
                    [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                })}
                role="presentation"
            >
                <Card className="gx-card" title={title}>
                    <Form
                        {...formItemLayout}
                        form={form}
                        name="update"
                        onFinish={onFinish}
                        initialValues={{
                            prefix: '216',
                        }}
                        scrollToFirstError
                    >
                        <Form.Item
                            name="username"
                            label="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your user name!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="first_name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your First name!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            label="Family Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Last name!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            label="Phone Number"
                            rules={[{required: true, message: 'Please input your phone number!'}]}
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <Form.Item
                            name="user_email"
                            label="Email"
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <Form.Item
                            name="ig_username"
                            label="Instagram user name"
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <Form.Item
                            name="ville"
                            label="Ville"
                            rules={[{required: true, message: 'Please select your city!'}]}
                        >
                            <FormControl>
                                <Select
                                    multiple={true}
                                    labelId="demo-simple-select-label"
                                    value={selectedVille}
                                    onChange={handleChangeVille}
                                    defaultValue={selectedVille}
                                >
                                    {tawaCities.map(option => {
                                        return <MenuItem value={option.value}
                                                         key={option.value}>{option.label}</MenuItem>
                                    })}
                                </Select>

                            </FormControl>
                        </Form.Item>
                        <Form.Item
                            name="marque_name"
                            label="Marque name"
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <Form.Item
                            name="business_sector_id"
                            label="Business sector"
                            rules={[{required: true, message: 'Please select your Business sector!'}]}
                        >
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={selectedBusinessSector}
                                    onChange={handleChangeBusinessSector}
                                >
                                    {businessSector.map(option => {
                                        return <MenuItem value={option.business_sector_id}
                                                         key={option.business_sector_id}>{option.label}</MenuItem>
                                    })}
                                </Select>

                            </FormControl>
                        </Form.Item>

                        <Form.Item
                            name="cible"
                            label="Cible"
                            rules={[{required: true, message: 'Please select your gender!'}]}
                        >
                            <Checkbox.Group onChange={handleChangeCible}>
                                <Checkbox className={"gx-ml-0 gx-mr-1 gx-mb-1"} key='H' value="H"> Homme</Checkbox>
                                <Checkbox className={"gx-ml-0 gx-mr-1 gx-mb-1"} key='F' value="F"> Femme</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>

                        <Form.Item name="ages"
                                   label="Age"
                                   rules={[{required: true, message: 'Please select your age!'}]}
                        >
                            <FormControl className={classes.formControl}>
                                <Select
                                    multiple={true}
                                    labelId="demo-simple-select-label"
                                    id="age"
                                    value={age}
                                    onChange={handleChangeAge}
                                    defaultValue={age}
                                >
                                    <MenuItem value={'18-24'}>18-24</MenuItem>
                                    <MenuItem value={'25-34'}>25-34</MenuItem>
                                    <MenuItem value={'35-44'}>35-44</MenuItem>
                                    <MenuItem value={'45-60'}>45-60</MenuItem>
                                </Select>
                            </FormControl>
                        </Form.Item>

                        <Form.Item
                            name="categories"
                            label="Categories"
                            rules={[{required: true, message: 'Please select your Categories!'}]}
                        >
                            <Checkbox.Group onChange={handleChangeCategories}>
                                {categories.map(option => {
                                    return <Checkbox className={"gx-ml-0 gx-mr-1 gx-mb-1"} key={option.value}
                                                     value={option.category_id}>{option.label}</Checkbox>
                                })}
                            </Checkbox.Group>
                        </Form.Item>

                        <Form.Item
                            name="user_passwordhash"
                            label="Password"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input your password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password autoComplete={"new-password"} />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['user_passwordhash']}
                            hasFeedback
                            rules={[
                                {
                                    required: false,
                                    message: 'Please confirm your password!',
                                },
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if ((!value && !getFieldValue('user_passwordhash')) || getFieldValue('user_passwordhash') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password autoComplete={"new-password"} />
                        </Form.Item>

                        <Form.Item
                            label={"Advertiser picture"}>
                            <div className={"group-file-upload"}>
                                <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={handleUploadClick}
                                />
                                <Button icon={<UploadOutlined/>} onClick={() => {
                                    document.getElementById('contained-button-file').click()
                                }}>Click to upload</Button>
                            </div>
                        </Form.Item>
                        {ad_efile_id ?
                            <Card title="Advertiser picture" className="gx-card clearfix">
                                <Upload
                                    listType="picture-card"
                                    fileList={adPicture}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                >
                                </Upload>
                                <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                    <img alt="Talent picture" style={{width: '100%'}} src={previewPicture}/>
                                </Modal>
                            </Card> : ''
                        }
                        <Form.Item {...tailFormItemLayout} >
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                            <Button type="secondary" onClick={() => disableDrawerAdd(false)}><IntlMessages
                                id="drawer.cancel"/></Button>
                        </Form.Item>
                    </Form>
                </Card>

            </div>
        );
    };

    return (
        <Drawer anchor={'right'} open={stateDrawer}
                onClose={toggleDrawer(false)}>
            {FormProfile('right')}
        </Drawer>
    )
};

export default AddAd;
