const sortByKey = (array, key) => {
  return array.sort(function(a, b) {
    var x = a[key]; var y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

const cities = [{
    label: "Grand Tunis",
    value: "TN"
  },
  {
    label: "Sfax",
    value: "SF",
  },
  {
    label: "Sousse",
    value: "SO",
  },
  {
    label: "Gabes",
    value: "GA",
  },
  {
    label: "Kairouan",
    value: "KR",
  },
  {
    label: "Bizerte",
    value: "BI",
  },
  {
    label: "Gafsa",
    value: "GF",
  },
  {
    label: "Nabeul",
    value: "NA"
  },
  // {
  //   label: "Ariana",
  //   value: "AR"
  // },
  {
    label: "Kasserine",
    value: "KA",
  },
  {
    label: "Monastir",
    value: "MO",
  },
  {
    label: "Tataouine",
    value: "TA",
  },
  {
    label: "Medenine",
    value: "MD"
  },
  {
    label: "Beja",
    value: "BE"
  },
  {
    label: "Jendouba",
    value: "JE"
  },
  {
    label: "El Kef",
    value: "KE"
  },
  {
    label: "Mahdia",
    value: "MA",
  },
  {
    label: "Sidi Bouzid",
    value: "SB",
  },
  {
    label: "Tozeur",
    value: "TO",
  },
  {
    label: "Siliana",
    value: "SI",
  },
  {
    label: "Kebili",
    value: "KB",
  },
  {
    label: "Zaghouan",
    value: "ZA",
  },
  {
    label: "TRE",
    value: "TRE",
  },
  // {
  //   label: "Ben Arous",
  //   value: "BA"
  // },
  // {
  //   label: "Manouba",
  //   value: "MN",
  // }
];

export const tawaCities = sortByKey(cities, 'label');
