import React, {useEffect} from "react";
import {Avatar, Popover} from "antd";
import siteConfig from "../../config/site.config";
import {Link} from "react-router-dom";
import BaseApiService from "../../util/BaseApiService";
import UpdateProfile from "../../routes/talent-info/talent-profile/update-profile";
import UpdateProfileAd from "../../routes/profile-ad-info/my-profile/update-profile-ad";
import UpdateProfileAdmin from "../../routes/admin-view/update-profile/update-profile-admin";
const baseApiService = new BaseApiService();

const UserInfo = (props) => {
    const [profile, setProfile] = React.useState({});
    const [user, setUser] = React.useState({});
    const [showDrawerUpdate, setShowDrawerUpdate] = React.useState(false);

    useEffect(() => {
        getMe();
    }, [props]);

    const getMe = () => {
        baseApiService.getMe()
            .then(res => {
                setUser(res.data.data.user || null);
                setProfile(res.data.data.profile || null);
            });
    }

    const EnableDrawerUpdateProfile = () => {
        setShowDrawerUpdate(true)
    }

    const disableDrawerUpdate = (event) => {
        setShowDrawerUpdate(event);
        getMe()
    };

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li><Link to={"/me"}>My profile</Link></li>
            <li onClick={() => EnableDrawerUpdateProfile()}>Edit my account</li>
            <li onClick={() => logout()}>Logout</li>
        </ul>
    );

    const logout = () => {
        localStorage.clear();
        window.location.href = siteConfig.loginUrl;
        // window.location.href = siteConfig.loginUrl + '/tryRedirection/' + encodeURIComponent(siteConfig.appBaseUrl)
    }

    return (
        <div className="gx-flex-row gx-align-items-center gx-avatar-row">
            <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
                <Avatar src={siteConfig.AppConfig.baseStyleImageUrl + profile?.profile_efile_id}
                        className="gx-size-30 gx-pointer gx-mr-3" alt=""/>
            </Popover>
            {showDrawerUpdate && siteConfig.IsTalent === 'true' ?
                <UpdateProfile enable_update={showDrawerUpdate} disableDrawerUpdate={disableDrawerUpdate}/>
                : ''
            }
            {showDrawerUpdate && siteConfig.IsAD === 'true' ?
                <UpdateProfileAd enable_update={showDrawerUpdate} disableDrawerUpdate={disableDrawerUpdate}/>
                : ''
            }
            {showDrawerUpdate && siteConfig.IsAdmin === 'true' ?
                <UpdateProfileAdmin enable_update={showDrawerUpdate} disableDrawerUpdate={disableDrawerUpdate}/>
                : ''
            }
        </div>
    );
}

export default UserInfo;
