import BaseApiService from "../BaseApiService";
import * as axios from "axios";
import {apiUrl} from "../../config/site.config";

export class CountryApiService extends BaseApiService {
  constructor() {
    super('country');
  }

  find() {
    return axios.get(apiUrl + '/' + this.entity_name + '/find/');
  }

  getStates(countryCode) {
    return axios.get(apiUrl + '/' + this.entity_name + '/' + countryCode + '/states');
  }

}

export const countryApiService = new CountryApiService();
