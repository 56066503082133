import BaseApiService from "../BaseApiService";
import * as axios from "axios";
import {apiUrl} from "../../config/site.config";

class TalentsApiService extends BaseApiService {
  constructor() {
    super('influencer');
  }

  getTalentByCode(username, fulldata = true) {
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getinfluenceByusername/'+ username + '?' + (fulldata ? '&fulldata=1' : ''));
  }

  getAllInvitationByTalent(talent_id) {
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getAllinvivtation?influencer_id='+ talent_id);
  }

  getCompainTalentDetails(talent_id, compain_id) {
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getCompainTalentDetails?influencer_id='+ talent_id + '&compain_id=' + compain_id);
  }

  getCompainDetails(compain_id) {
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getCompainDetails?compain_id='+ compain_id);
  }

  sendRequestPublicCompain(data) {
    return this.setHeaderToken().post(apiUrl + '/' + this.entity_name + '/sendRequestPublicCompain', data);
  }

  acceptInvitation(data) {
    return this.setHeaderToken().post(apiUrl + '/' + this.entity_name + '/acceptInvitation', data);
  };

  declineInvitation(data) {
    return this.setHeaderToken().post(apiUrl + '/' + this.entity_name + '/declineInvitation', data);
  };

  getStatCompainDetails(compain_influencer_id) {
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getStatCompainByTalent?compain_influencer_id='+ compain_influencer_id);
  };

  updateTalent(data) {
    return this.setHeaderToken().put(apiUrl + '/' + this.entity_name + '/updateInfluencer', data);
  };

  getTalents(params = {}) {
    const params_encoded = (params && Object.keys(params).length) ? encodeURIComponent(JSON.stringify(params)) : '';
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getTalents/'+ params_encoded);
  };

  updateTalentInfo(data) {
    return this.setHeaderToken().post(apiUrl + '/' + this.entity_name + '/updateInfluencerInfo', data);
  };
  getAllInvitations() {
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getAllInvitationNotAccepted');
  };

}

const talentApiService = new TalentsApiService();

export default talentApiService;
